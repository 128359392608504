.form-floating {
  &.white-bg {
    .form-control {
      background-color: $white-clr;
      margin-top: 20px;
    }
  }
  .form-control,
  .form-select {
    background-color: $light-gray;
    border-radius: $input-radius;
    border: none;
    @include font(0.938rem, $med-font, $primary-txt-clr);
    height: calc(3.5rem + 9px);
    padding-left:0.938rem;
    padding-right:0.938rem;
    & ~ label {
      opacity: 1;
    }
    &:focus,
    &:hover {
      -moz-box-shadow: 0 0 0 1px rgba(105, 41, 139, 1);
      box-shadow: 0 0 0 1px rgba(105, 41, 139, 1);
      -webkit-box-shadow: 0 0 0 1px rgba(105, 41, 139, 1);
    }
    &.simple-input {
      padding: 10px 8px;
      height: auto;
    }
    &:disabled{
      pointer-events: none;
      opacity: .6;
    }
  }
  & > label {
    color: $gray-color;
    font-family: $font;
    @media (min-width: 1200px) {
      white-space: nowrap;
    }
    @include size(phone){
      font-size: 14px;
    }
  }
}
.upload-file {
  background: $light-gray;
  border-radius: 10px;
  overflow: hidden;
  padding: 1.875rem;
  position: relative;
  text-align: center;
  cursor: pointer;
  border: dashed 1px #c8c8c8;
  .form-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
  }
  img {
    margin: 5px 0 29px;
  }
  h5 {
    @include font($font-size-p, $med-font, $primary-txt-clr);
    margin-bottom: 0.313rem;
  }
  p {
    @include font($font-size-md, normal, $gray-color);
  }
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-flex;
  width: 24px;
  height: 14px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
      + .slider {
        background: $primary-clr;
        @include gradient(to right, $gradient-color);
        &::before {
          @include transform(translateX(10px));
        }
      }
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include transition(all ease-in-out 0.4s);
    background-color: $gray-color;
    border-radius: $input-radius;
    &::before {
      content: "";
      @include position(absolute, 2px, 2px, 10px, 10px);
      background-color: $white-clr;
      @include transition(all ease-in-out 0.4s);
      border-radius: $input-radius;
    }
  }
}
// Custom Checkbox Style
.custom-checkbox, .form-check {
  display: flex;
  width: 100%;
  padding-left: 0;
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    &:checked + label::after {
      content: "";
      display: block;
      @include position(absolute, 3px, 5px, 9px, 6px);
      border: solid $white-clr;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    &:checked + label.background-check-label::after {
      @media (max-width: 990px) {
        top: 12px;
      }
    }
    &:checked + label::before {
      @include gradient(to right, $gradient-color);
      border: none;
    }
    &[type=radio]{
     + label{
       &::before{
         border-radius: 50%;
         z-index: 1;
         margin-right: 10px;
       }
      }
      &:checked + label::before{
        border: 2px solid $primary-light-clr;
        background: none;
      }
      &:checked + label::after {
        width: 16px;
        height: 16px;
        background: $primary-light-clr;
        border-radius: 50%;
        transform: inherit;
        border: 4px solid #fff;
        z-index: 0;
        vertical-align: middle;
        display: inline-block;
        left: auto;
        top: auto;
      }
    }
  }
  label {
    position: relative;
    cursor: pointer;
    @include flexM(flex, center, start);
    width: 100%;
    @media (max-width: 560px) {
      align-items: flex-start;
    }
    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #777a7e;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
      border-radius: 2px;
      height: 16px;
      min-width: 16px;
    }
  }
}
.form-check {
  min-height: auto;
  margin-bottom: 0;
  position: relative;
  .form-check-label {
    @include font($font-size-p, normal, $primary-txt-clr);
  }
}
.form-select,
.form-control {
  &.form-select-sm,
  &.form-control-sm {
    border: none;
    background-color: $light-gray;
    background-size: 10px 8px;
    @include font($font-size-p, $med-font, $primary-txt-clr);
    padding: 6px 15px;
    &:focus {
      box-shadow: 0 0 0 1px #69298b;
    }
  }
}
.send-sms {
  position: relative;
  .white-bg {
    border-radius: $input-radius;
  }
  .send-mail {
    position: absolute;
    right: 18px;
    top: 18px;
    width: 45px;
    height: 45px;
    @include flexM(flex, center, center);
    border-radius: 50%;
    border: none;
    @include gradient(45deg, $gradient-color);
    @media (max-width: 767px) {
      right: 12px;
    }
  }
  .form-control{
    @media (max-width: 767px) {
      padding-right: 52px;
    }
  }
}
.gray-bg {
  &.form-floating {
    .form-control,
    .form-select {
      background-color: rgba(33, 35, 38, 0.1);
    }
  }
}
.gray-input {
  .gray-bg {
    &.form-control {
      background-color: rgba(33, 35, 38, 0.1);
      border: none;
      border-radius: $input-radius;
      font-size: $font-size-13;
      padding: 13px 20px;
      &:focus {
        box-shadow: 0 0 0 1px #69298b;
      }
    }
  }
}

.label-up {
  input {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  & ~ label {
    opacity: .8;
    transform: scale(.73) translateY(0.2rem) translateX(0.45rem);
  }
}

//Input type number arrow disable
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.react-datepicker-popper{
  z-index: 99999;
}
.react-datepicker-popper[data-placement^="bottom"],
.react-datepicker-popper[data-placement^="top"] {
  .react-datepicker--time-only, .react-datepicker {
    .react-datepicker__triangle {
      transform: inherit !important;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// Textarea label up style
.form-floating {
  .form-select{
    &.simple-input{
      padding-right: 27px;
    }
  }
  textarea {
    &.form-control {
      border-radius: $input-radius;
      &:focus,
      &:not(:placeholder-shown) {
        ~ label {
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
          background-color: #f8f8f8;
          height: auto;
          padding: 9px 0px 0px 10px;
          width: 100%;
          border-radius: 5px 0 0 0;
          transform: scale(0.98) translateY(0rem) translateX(0.2rem);
          font-size: 12.5px;
          font-weight: 500;
          @media (max-width: 767px){
            transform: scale(0.95) translateY(0rem) translateX(0.2rem);
          }
        }
      }
    }
    &::-webkit-scrollbar-track {
      border-radius: $input-radius;
      background-color: #f8f8f8;
    }
    &::-webkit-scrollbar {
      width: 8px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: $input-radius;
      background-color: #999999;
    }
  }
}
.react-datepicker__input-container{
  caret-color: transparent;
  width: 100%;
  ::-webkit-input-placeholder {
    color: #212326;
  }
  :-ms-input-placeholder {
    color: #212326;
  }
  :-moz-placeholder {
    color: #212326;
  }
  input{
    cursor: pointer;
    @media only screen and (max-width: 1366px) and (min-width: 1024px) {
      font-size: 13px !important;
    }
    &::placeholder{
      color: #212326;
      @media only screen and (max-width: 1366px) and (min-width: 1024px) {
        font-size: 13px !important;
      }
    }
  }
}

input[type="date"] {
  &::-webkit-calendar-picker-indicator{
    background: url('../../img/drop-arrow.svg') no-repeat;
  }
}
select{
  &.form-select{
    background-image: url('../../img/drop-arrow.svg');
    background-size: auto;
    @media (max-width: 767px){
      background-position: right 0.2rem center;
    }
  }
}

// Floating Label Position modify
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  opacity: .8;
  transform: scale(.73) translateY(0.2rem) translateX(0.45rem);
}

// Input spinner
.input-spinner{
  display: flex;
  .count{
    @include font($font-size-13, normal, $primary-txt-clr);
    @include flexM(inline-flex, center, center);
    width: 30px;
  }
  .add, .min{
    width: 14px;
    height: 14px;
    background-color: $gray-color;
    border: none;
    @include flexM(inline-flex, center, center);
    @include font($font-size-13, bold, $white-clr);
    line-height: 13px;
    cursor: pointer;
  }
}

.choose-country{
  select{
    border: none;
    background-color: #f8f8f8;
    height: 100%;
    border-radius: $input-radius;
    padding-left: 12px;
    outline: none;
    font-size: 15px;
    @include size(tablet-up){
      margin-bottom: 20px;
      height: calc(100% - 20px);
      padding-right: 15px;
    }
    &:focus, &:hover {
      box-shadow: 0 0 0 1px #69298b;
    }
  }
}
.error-field{
  .form-control{
    box-shadow: 0 0 0 1px #ef2c2c;
  }
}
.react-select{
  .css-1s2u09g-control, .css-13cymwt-control{
    border: none;
    background-color: $light-gray;
    border-radius: $input-radius;
    min-height: 65px;
  }
  .css-1pahdxg-control, .css-t3ipsp-control{
    border: none;
    background-color: $light-gray;
    border-radius: $input-radius;
    min-height: 65px;
    box-shadow: 0 0 0 1px #69298b;
    &:focus{
      box-shadow: inherit;
    }
    &:hover{
      box-shadow: 0 0 0 1px #69298b;
    }
  }
}
.choose-country{
  display: flex;
  .react-select{
    width: 27%;
    position: relative;
    z-index: 11;
    @media (max-width: 410px) {
      width: 36%;
    }
  }
  .text-field{
    width: calc(73% - 20px);
    margin-left: 20px;
    @media (max-width: 410px) {
      width: calc(64% - 20px);
    }
  }
}
.single-radio-btn{
  .form-check-input{
    cursor: pointer;
    &:checked{
      position: relative;
      &[type=radio]{
        border: 2px solid $primary-light-clr;
        box-shadow: inherit;
        background-color: $white-clr;
      }
      &::after{
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 8px;
        height: 8px;
        background-color:  $primary-light-clr;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }
}
.admin-select-filter{
  @include size(tablet-up){
    margin: 0;
  }
  .form-select{
    height: auto;
    padding: 13px 35px 13px 20px;
    min-width: 135px;
    @include size(tablet-up){
      min-width: inherit;
      width: 40px;
      padding: 11px 21px;
      background:white url(../../img/filter-icon.svg) center no-repeat;
    }
  }
  label{
    display: none;
  }
}
@media (max-width: 600px) {
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input,
  select:focus,
  select,
  textarea {
      font-size: 16px !important;
  }
}