.contact-us{
  .banner-image{
    min-height: 450px;
    justify-content: flex-end;
    flex-direction: column;
    align-items: baseline;
    padding: 50px;
    background-position: center;
    @media (max-width: 767px) {
      min-height: 200px;
      padding: 20px;
    }
    h5{
      @include font($font-size-40, normal, $white-clr);
      margin-bottom: 5px;
      font-family: $font-caveat;
      @media (max-width: 767px) {
        font-size: $font-size-20;
      }
    }
  }
}
.contact-info-sec{
  margin: 50px 0;
  .contact-info-wrp{
    box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
    border-radius: $input-radius;
    padding: 50px 70px;
    background: $white-clr;
    @media (max-width: 767px) {
      padding: 0 30px 30px 30px;
    }
    h4{
      @include font($font-size-15, $semi-font, $primary-txt-clr);
      margin-bottom:15px;
      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }
    p, a{
      @include font($font-size-15, normal, $gray-color);
      margin-top: 6px;
      display: block;
      line-height: 24px;
    }
  }
}
.contact-form{
  margin-bottom: 200px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
  .query-form, .map{
    padding: 50px;
    background: $white-clr;
    box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
    border-radius: $input-radius;
    @media (max-width: 767px) {
      padding:30px;
      margin-bottom: 50px;
    }
    h3{
      @include font($font-size-lg, $semi-font, $primary-txt-clr);
      margin-bottom: 2px;
      @media (max-width: 767px) {
        font-size: $font-size-20;
        margin-bottom: 5px;
      }
    }
    p{
      @include font($font-size-15, normal, $gray-color);
      line-height: 22px;
      margin-bottom: 50px;
      @media (max-width: 767px) {
        margin-bottom: 30px;
        font-size: $font-size-14;
      }
    }
    img{
      max-width: 100%;
    }
    .error-message{
      @media (max-width: 767px) {
        margin: 10px 0 0;
      }
    }
  }
}