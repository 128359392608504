.stripe-element-wrp{
  position: relative;
  @include size(tablet-up){
    margin-bottom: 20px;
  }
  .StripeElement{
    background-color: $light-gray;
    border-radius: $input-radius;
    height: calc(3.5rem + 9px);
    padding:36px 0px 0.938rem;
    border: 1px solid transparent;
    cursor: text;
    .__PrivateStripeElement{
      left: 13px;
      width: 0;
    }
    + .stripe-element-label{
      @include font(16px, 400, $gray-color);
      position: absolute;
      top: 16px;
      left: 13px;
      @include transition(all ease-in-out 0.1s);
    }
    &.StripeElement--focus, &.StripeElement--complete, &.StripeElement--invalid{
      .__PrivateStripeElement{
        width: 100%;
      }
      + .stripe-element-label{
        font-size: 12px;
        top: 16px;
        opacity: 0.7;
        @include transition(all ease-in-out 0.1s);
      }
    }
    &.StripeElement--focus, &:hover {
      border-color: $primary-clr;
    }
    &.StripeElement--invalid{
      border-color: $error-clr;
    }
  }
}