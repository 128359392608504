.about-sec{
  padding: 30px 0 60px;
  h1{
    @include font($font-size-40, normal, $primary-light-clr);
    margin-bottom: 10px;
    font-family: $font-caveat;
    @include size(tablet){
      font-size: $font-size-xl;
    }
    @media (max-width: 767px) {
      font-size: $font-size-20;
    }
  }
  p{
    @include font($font-size-40, normal, $primary-txt-clr);
    line-height: 1.38;
    max-width: 800px;
    margin: 0 auto 40px;
    @include size(tablet){
      font-size: $font-size-xl;
    }
    @media (max-width: 767px) {
      font-size: $font-size-18;
    }
    .small-text{
      @include font($font-size-16, normal, $gray-color);
      display: flex;
      margin-top: 25px;
      @media (max-width: 767px) {
        font-size: $font-size-14;
        line-height: 22px;
      }
    }
  }
  .thumb-img{
    max-height: 341px;
    overflow: hidden;
    border-radius: $input-radius;
    img{
      width: 100%;
    }
  }
}
.expertise-sec{
  margin: 100px 0;
  @media (max-width: 767px) {
    margin: 50px 0;
  }
  .expertise-wrp{
    box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
    border-radius: $input-radius;
    padding: 70px;
    background: $white-clr;
    @include size(tablet){
      padding: 50px;
    }
    @media (max-width: 767px) {
      padding: 30px;
    }
    .item{
      &.mob-mr-b-20{
        @include size(tablet){
          margin-bottom: 30px;
        }
      }
      @media (max-width: 767px) {
        @include flexM(flex, center, center);
        flex-direction: column;
        text-align: center;
        margin: 20px 0;
      }
      .icon{
        width: 50px;
        height: 50px;
        border-radius: $input-radius;
        background-color: #f7ebfd;
        @include flexM(flex, center, center);
        margin-bottom: 30px;
      }
      h4{
        @include font($font-size-xl, $e-bold-font, $primary-txt-clr);
        margin-bottom: 8px;
        @media (max-width: 767px) {
          font-size: $font-size-20;
        }
        small{
          @include font($font-size-18, normal, $primary-txt-clr);
          margin-top: 10px;
          display: block;
          @media (max-width: 767px) {
            font-size: $font-size-16;
          }
        }
      }
      p{
        @include font($font-size-14, normal, $gray-color);
        line-height: 1.79;
      }
    }
  }
}
.who-we-are-sec{
  .gx-5{
    @media (max-width: 767px) {
      --bs-gutter-x: 1.5rem;
    }
  }
  .image{
    position: relative;
    padding: 50px 0;
    z-index: 1;
    @media (max-width: 767px) {
      padding: 0 0 30px;
    }
    img{
      max-width: 100%;
      border-radius: $input-radius;
      width: 100%;
    }
    &::after{
      content: "";
      @include position(absolute, 0, 50px, 100%, calc(100% + 100px));
      background: $gray-color;
      opacity: 0.03;
      border-radius: $input-radius;
      z-index: -1;
      @media (max-width: 992px) {
        display: none;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  .content{
    @include flexM(flex, flex-start, center);
    height: 100%;
    flex-direction: column;
    @media (min-width: 1024px) {
      padding: 40px 0;
    }
    h6{
      @include font($font-size-xl, normal, $primary-light-clr);
      margin-bottom: 10px;
      font-family: $font-caveat;
      @media (max-width: 767px) {
        font-size: $font-size-20;
      }
    }
    h2{
      @include font($font-size-xl, normal, $primary-txt-clr);
      line-height: 1.5;
      @media (max-width: 767px) {
        font-size: $font-size-22;
      }
    }
    p{
      @include font($font-size-16, normal, $gray-color);
      line-height: 2;
      margin-top: 20px;
      @media (max-width: 767px) {
        font-size: $font-size-15;
        line-height: 2;
      }
    }
  }
}

.value-section{
  margin: 100px 0;
  @media (max-width: 767px) {
    margin: 50px 0;
  }
  &.core-value{
    background-color: #fff;
    padding: 50px 0;
  }
  .value-des{
    @include font($font-size-16, normal, $gray-color);
    line-height: 2.2;
    max-width: 800px;
    margin: 30px auto 0;
    text-align: center;
    @media (max-width: 767px) {
      text-align: left;
    }
  }
  .heading{
    text-align: center;
    h6{
      @include font($font-size-xl, normal, $primary-light-clr);
      margin-bottom: 10px;
      font-family: $font-caveat;
      @media (max-width: 767px) {
        font-size: $font-size-20;
      }
    }
    h2{
      @include font($font-size-xl, normal, $primary-txt-clr);
      line-height: 1.5;
      max-width: 700px;
      margin: 0 auto 40px;
      @media (max-width: 767px) {
        font-size: $font-size-22;
      }
    }
  }
  .white-wrapper{
    box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
    border-radius: $input-radius;
    padding: 50px 50px 0 50px;
    background: $white-clr;
    @media (max-width: 767px) {
      padding: 30px;
    }
    .image{
      img{
        max-width: 100%;
      }
    }
    .item{
      display: flex;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        margin-bottom: 0;
        margin-top: 30px;
      }
      .icon{
        width: 50px;
        height: 50px;
        border-radius: $input-radius;
        background-color: #f7ebfd;
        @include flexM(flex, center, center);
        margin-right: 25px;
      }
      .content{
        width: calc(100% - 75px);
        h4{
          @include font($font-size-20, $heading-font, $primary-txt-clr);
          margin-bottom: 4px;
        }
        p{
          @include font($font-size-15, normal, $gray-color);
          line-height: 1.6;
        }
      }
    }
  }
}
.explore-now-sec{
  margin-bottom: 100px;
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
  .explore-banner{
    background-size: cover;
    padding: 83px 100px;
    border-radius: $input-radius;
    background-position: center;
    @media (max-width: 767px) {
      padding: 30px;
    }
    h6{
      @include font($font-size-xl, normal, $white-clr);
      margin-bottom: 10px;
      font-family: $font-caveat;
      @media (max-width: 767px) {
        font-size: $font-size-20;
      }
    }
    h2{
      @include font($font-size-40, $heading-font, $white-clr);
      line-height: 1.45;
      max-width: 420px;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        font-size: $font-size-lg;
      }
    }
    .button{
      font-weight: $bold-font-8;
      min-width: 200px;
      font-size: $font-size-13;
    }
  }
}
.gray-bg{
  .main-header{
    &.is-sticky{
      background-color: $light-gray;
    }
  }
}
section.press-page {
  margin-bottom: 50px;
}
.press-item{
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.07);
    border-radius: 10px;
    margin: 15px 0;
    background-color: $white-clr;
  .by-box{
    width: 100%;
    @include flexM(flex, center, center);
    border-radius: 10px;
    overflow: hidden;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
    h3{
      @include font($font-size-16, 100, $gray-color);
    }
    img{
      max-width: 100%;
    }
  }
  a{
    &:hover{
      color: #212529;
    }
  }
  .content{
    .box-head{
      @include flexM(flex, center, space-between);
      margin-top: 10px;
      + h2{
        margin: 12px 0 10px;
      }
      .source{
        font-size: $font-size-14;
        font-style: italic;
      }
    }
    h2{
      @include font(21px, $heading-font, $black-color);
      line-height: 30px;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        font-size: $font-size-18;
        line-height: 26px;
        margin-bottom: 12px;
      }
    }
    .time{
      @include font($font-size-13, normal, $gray-color);
    }
    .blog-des{
      font-size: $font-size-15;
      line-height: 24px;
    }
  }
}