.regular {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	> * {
		padding-right: calc(var(--bs-gutter-x) * .5);
		padding-left: calc(var(--bs-gutter-x) * .5);
		margin-top: var(--bs-gutter-y);
	}
}
.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
	&:focus {
		outline: 0;
	}
}
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	margin-bottom: 69px;
	&::after{
		content: "";
    position: absolute;
    bottom: -70px;
    left: 12px;
    right: 12px;
    height: 50px;
    background: #fff;
    box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
    border-radius: 10px;
	}
	.slick-list {
		-webkit-transform: translate3d(0,0,0);
		-moz-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0);
		-o-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}
	.slick-track {
		-webkit-transform: translate3d(0,0,0);
		-moz-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0);
		-o-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}
}
.slick-track {
	position: relative;
	display: block;
	top: 0;
	left: 0;
	&:after {
		display: table;
		content: '';
		clear: both;
	}
	&:before {
		display: table;
		content: '';
	}
}
.slick-loading {
	.slick-slide {
		visibility: hidden;
	}
	.slick-track {
		visibility: hidden;
	}
}
.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}
.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
	img {
		display: block;
	}
}
[dir=rtl] {
	.slick-slide {
		float: right;
	}
}
.slick-slide.slick-loading {
	img {
		display: none;
	}
}
.slick-slide.dragging {
	img {
		pointer-events: none;
	}
}
.slick-initialized {
	.slick-slide {
		display: block;
	}
}
.slick-vertical {
	.slick-slide {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}
.slick-arrow.slick-hidden {
	display: none;
}

// Next prev button style
.slick-next {
	position: absolute;
	right: 32px;
	bottom: -57px;
	width: 25px;
	height: 25px;
	z-index: 99;
	border: none;
	background: url(../../img/left-arrow.svg) center no-repeat;
	color: transparent;
}
.slick-prev {
	position: absolute;
	left: 32px;
	width: 25px;
	height: 25px;
	bottom: -57px;
	z-index: 99;
	border: none;
	background: url(../../img/left-arrow.svg) center no-repeat;
	color: transparent;
	transform: rotate(180deg);
}
