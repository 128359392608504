// Home banner style
.banner-sec{
    position: relative;
    background-size: cover;
    min-height: 78vh;
    background-position-x: center;
    @include size(tablet){
        min-height: 67vh;
    }
    @media (max-width: 767px) {
        min-height: 70vh;
    }
    .banner-txt{
        padding: 19vh 0;
        @include size(tablet){
            padding: 13vh 0;
        }
        @media (max-width: 767px) {
            padding: 10vh 0;
        }
        h1{
            @include font($font-size-35, $e-bold-font, $white-clr);
            margin-bottom: 17px;
            text-transform: $capital;
            @include size(tablet){
                font-size: $font-size-xl;
            }
            @media (max-width: 767px) {
                font-size: $font-size-lg;
                line-height: 1.4;
            }
        }
        p{
            @include font($font-size-p, normal, $white-clr);
            line-height: 2;
            max-width: 500px;
            margin-bottom: 3.063rem;
            @media (max-width: 767px) {
                font-size: $font-size-13;
                opacity: 0.8;
                line-height: 1.92;
                margin-bottom: 0;
            }
        }
    }
    &.home{
        .inner-pg-logo{
            display: none;
        }
    }
}
.page{
    .home-logo{
        display: none;
    }
    .nav-links {
        li{
            &:last-of-type {
                .drop-icon{
                    background: #e2e3e3 !important;
                    color: $primary-txt-clr;
                    &::after{
                        border-top-color: $primary-txt-clr !important;
                    }
                }
            }
        }
    }
}

// Header section style
.main-header{
    padding: 24px 0;
    @include transition(all ease-in-out 0.2s);
    z-index: 222;
    position: sticky;
    left: 0;
    right: 0;
    &.is-sticky{
        position: fixed;
        @include transition(all ease-in-out 0.2s);
    }
    .right-navbar{
        @include flexM(flex, center, flex-end);
        height: 100%;
        .nav-links{
            display: flex;
            li{
                list-style: none;
                margin-left: 10px;
                position: relative;
                a, button{
                    @include font($font-size-12, $heading-font, $white-clr);
                    @include flexM(flex, center, center);
                    @include gradient(to right, $gradient-color);
                    border: 1px solid $primary-clr;
                    border-radius: $input-radius;
                    text-transform: $capital;
                    padding: 13px 30px;
                    cursor: pointer;
                    .flag-space{
                        margin-right: 10px;
                    }
                    @include size(tablet){
                        padding: 13px 15px;
                    }
                }
                &.active-country{
                    position: relative;
                    a{
                        img{
                            position: absolute;
                            right: 25px;
                            width: 25px;
                            margin: auto;
                            top: 0;
                            bottom: 0;
                        }
                    }
                }
                &:last-of-type{
                    .drop-icon{
                        border-color: rgba(255, 255, 255, 0.25);
                        background: rgba(255, 255, 255, 0.15);
                        padding: 11.5px 16px 11.5px 10px;
                        img{
                            width: 23px;
                            margin-right: 10px;
                        }
                        &::after{
                            content: "";
                            @include position(inherit, 20px, auto, 0, 0);
                            border-top: solid 4px $white-clr;
                            border-bottom: solid 4px transparent;
                            border-left: solid 4px transparent;
                            border-right: 4px solid transparent;
                            margin: 4px 0 0 15px;
                        }
                    }
                    .filter-dropdown{
                        left: auto;
                        right: 0;
                        max-width: 200px;
                    }
                }
                ul{
                    li{
                        a{
                            background: none;
                            @include font($font-size-p, normal, $primary-txt-clr);
                            padding: 0 !important;
                            border: none;
                            text-transform: none;
                        }
                    }
                }
            }
            @media (max-width: 767px) {
                display: none;
            }
            .filter-dropdown{
                li{
                    a{
                        justify-content: flex-start;
                        line-height: 28px;
                    }
                }
            }
        }
        .user-info{
            display: none;
            .user{
                display: flex;
                align-items: center;
                .user-thumb{
                    margin-right: 10px;
                    max-width: 30px;
                    max-height: 30px;
                    @media (max-width: 767px) {
                        margin-right: 0;
                    }
                    img{
                        object-fit: cover;
                        border-radius: 4px;
                        width: 30px;
                        height: 30px;
                    }
                }
                .user-txt{
                    h5{
                        @include font($font-size-14, normal, $primary-txt-clr);
                        margin-bottom: 2px;
                    }
                    p{
                        @include font($font-size-12, normal, $gray-color);
                    }
                }
                .user-action{
                    margin-left: 10px;
                }
            }
        }
    }
}
.mobile-menu{
    .toggle-menu{
        @include font($font-size-12, $heading-font, $white-clr);
        @include flexM(flex, center, center);
        @include gradient(to right, $gradient-color);
        border: 1px solid $primary-clr;
        border-radius: $input-radius;
        text-transform: $capital;
        padding: 7.5px;
        cursor: pointer;
    }
}
.search-filter{
    margin: -70px 0;
    position: relative;
    z-index: 1;
    .container{
        @include size(tablet){
            max-width: 100%;
        }
    }
   .custom-row{
       display: flex;
       width: 100%;
       box-shadow: 2px 5px 40px 0 rgba(0, 0, 0, 0.1);
       background: $white-clr;
       border-radius: $input-radius;
       .form-filed{
           width: 100%;
           &.reverse-col{
            > div{
                display: flex;
                flex-flow: column;
                label{
                    order: 1;
                }
                input{
                    order: 2;
                }
                .error-message{
                    order: 3;
                }
            }
          }
           .form-floating{
            .label-up{
                ~ label{
                    opacity: 1;
                    transform: inherit;
                    padding: 0;
                }
            }
            .form-control{
                background-color: $white-clr;
                background-size: auto;
                height: auto;
                margin-top: 24px;
                &:hover{
                    box-shadow: none;
                }
            }
           }
       }
       .column{
           @include flexM(flex, center, flex-start);
           padding: 46px 40px;
           border-right: 1px solid rgba(94, 98, 103, 0.05);
            @media (max-width: 1200px) {
                padding: 46px 20px;
            }
            @include size(tablet){
                padding: 35px 15px;
            }
            .form-floating{
                @include size(tablet){
                    margin-bottom: 0;
                }
            }
           label{
            @include font($font-size-16, $semi-font, $primary-txt-clr);
            margin-bottom: 5px;
            @include size(tablet){
                font-size: $font-size-15;
            }
            i{
                font-style: normal;
                @include font($font-size-13, normal, $gray-color);
                @include size(tablet){
                    font-size: $font-size-12;
                }
            }
           }
           input, select{
                border: none;
                padding: 0;
                @include font($font-size-md, normal, $gray-color);
                width: 100%;
                outline: none;
                &.form-select, &:not(input){
                    background-image: url(../../img/drop-arrow.svg);
                    background-repeat: no-repeat;
                    background-position: right 0.01rem center;
                    background-size: contain;
                }
                &:focus{
                    box-shadow: none;
                }
                @include size(tablet){
                    font-size: $font-size-13;
                }
           }
           .location-icon{
               img{
                   width: 25px;
                   margin-right: 10px;
                   @include size(tablet){
                    width: 17px;
                    margin-right: 5px;
                    }
               }
               @media (max-width: 767px) {
                    display: none;
                }
           }
           .action-btn{
               display: flex;
                .search-btn{
                    border-radius: 50%;
                    width: 45px;
                    height: 45px;
                    @include flexM(flex, center, center);
                    @include gradient(45deg, $gradient-color);
                    border:  none;
                    margin-left: 20px;
                    @media (max-width: 767px) {
                        width: 100%;
                        border-radius: $input-radius;
                        margin: 23px 0 0 0;
                        text-transform: $capital;
                        font-size: $font-size-14;
                    }
                    &.mr-l-mob{
                        @media (max-width: 767px) {
                            margin-left: 15px;
                        }
                    }
                }
                .advance-filter{
                    border-radius: 50%;
                    width: 45px;
                    height: 45px;
                    @include flexM(flex, center, center);
                    background-color: #e9ddef;
                    border: none;
                    margin-left: 35px;
                }
            }
            &:nth-child(1){
                width: 33%;
                @include size(tablet){
                    width: 35%;
                }
                .form-filed{
                    width: calc(100% - 35px);
                    @include size(tablet){
                        width: calc(100% - 22px);
                    }
                }
            }
            &:nth-child(2){
                width: 19%;
            }
            &:nth-child(3){
                width: 18%;
            }
            &:nth-child(4){
                width: 30%;
                border-right: none;
                @media (max-width: 767px) {
                    flex-direction: column;
                }
                .form-filed{
                    width: calc(100% - 90px);
                    @include size(tablet){
                        width: calc(100% - 60px);
                    }
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
            @media (max-width: 767px) {
                width: 100% !important;
                padding: 15px 20px;
                border-bottom: 1px solid rgba(94, 98, 103, 0.05);
                border-right: 0;
            }
            .form-filed, .action-btn{
                @media (max-width: 767px) {
                    width: 100%;
               }
               input[type="number"]{
                &::placeholder{
                    color: #7d7d7d;
                }
               }
            }
       }
       @media (max-width: 767px) {
            flex-flow: column;
       }
       .react-datepicker-wrapper{
        width: 100%;
            input{
                &::placeholder{
                    color: #7d7d7d;
                }
            }
        }
   }
}
.best-rental-sec{
    background: $light-gray;
    padding: 160px 0 100px 0;
    @include size(tablet){
        padding: 140px 0 60px 0;
    }
    @media (max-width: 767px) {
        padding: 120px 0 50px 0;
    }
    .heading-sec{
        text-align: center;
        margin-bottom: 60px;
        @include size(tablet){
            margin-bottom: 40px;
        }
        @media (max-width: 767px) {
            margin-bottom: 35px;
        }
        h4{
            @include font($font-size-20, $med-font, $primary-light-clr);
            text-transform: $capital;
            margin-bottom: 25px;
            letter-spacing: 5px;
            @media (max-width: 767px) {
                font-size: $font-size-18;
                letter-spacing: 0;
                margin-bottom: 10px;
            }
        }
        p{
            @include font($font-size-22, normal, $primary-txt-clr);
            line-height: 1.82;
            max-width: 550px;
            margin: auto;
            @include size(tablet){
                font-size: $font-size-18;
            }
            @media (max-width: 767px) {
                font-size: $font-size-15;
                line-height: 30px;
            }
        }
    }
    .v-middle{
        @include flexM(flex, inherit, space-between);
        flex-flow: column;
        @media (max-width: 767px) {
            flex-flow: row;
            margin-top: 20px;
        }
        .item-list{
            @media (max-width: 767px) {
                width: calc(50% - 7.5px);
            }
        }
    }
    .item-list{
        position: relative;
        max-width: 100%;
        overflow: hidden;
        border-radius: $input-radius;
        cursor: pointer;
        img{
            width: 100%;
            @include transition(all ease-in-out 0.2s);
            @media (max-width: 767px) {
                height: 100%;
                object-fit: cover;
            }
        }
        .content{
            @include position(absolute, 0, 0, 100%, 100%);
            @include flexM(flex, baseline, flex-end);
            @include gradient(to bottom, $gradient-color2);
            right: 0;
            flex-flow: column;
            padding: 30px;
            @media (max-width: 767px) {
                padding: 15px;
            }
            h6{
                @include font($font-size-18, $heading-font, $white-clr);
                margin-bottom: 5px;
                @media (max-width: 767px) {
                    font-size: $font-size-15;
                }
            }
            p{
                @include font($font-size-13, normal, $white-clr);
                @media (max-width: 767px) {
                    font-size: $font-size-12;
                }
            }
        }
        &:hover{
            img{
                @include transition(all ease-in-out 0.2s);
                @include transform(scale(1.2));
            }
        }
        @media (max-width: 767px) {
            aspect-ratio: 3 / 3.5;
        }
    }
    .gx-5{
        @include size(desktop){
            --bs-gutter-x: 1.5rem;
        }
        @include size(tablet){
            --bs-gutter-x: 1.5rem;
        }
    }
}
.recent-list-sec{
    border-top: 1px solid rgba(33, 35, 38, 0.05);
    border-bottom: 1px solid rgba(33, 35, 38, 0.05);
    background: $light-gray;
    padding: 100px 0;
    @include size(tablet){
        padding: 60px 0;
    }
    @media (max-width: 767px) {
        padding: 50px 0;
        position: relative;
        overflow: hidden;
    }
    .heading-sec{
        margin-bottom: 78px;
        @include size(tablet){
            margin-bottom: 40px;
        }
        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
        h3{
            @include font($font-size-20, $med-font, $primary-light-clr);
            text-transform: $capital;
            margin-bottom: 10px;
            letter-spacing: 5px;
            @media (max-width: 767px) {
                font-size: $font-size-18;
                letter-spacing: 0;
                margin-bottom: 10px;
                text-align: center;
            }
        }
        p{
            @include font($font-size-18, normal, $primary-txt-clr);
            @media (max-width: 767px) {
                font-size: $font-size-15;
                line-height: 30px;
                text-align: center;
                margin-bottom: 30px;
            }
        }
        .align-right{
            @include flexM(flex, center, flex-end);
            height: 100%;
            @media (max-width: 767px) {
                justify-content: space-between;
            }
            .filter-list{
                position: relative;
                display: flex;
                box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
                i{
                    background: $white-clr;
                    @include font(13px, normal, $gray-color);
                    border-radius: $input-radius;
                    padding: 13px 60px 13px 20px;
                    cursor: pointer;
                    font-style: normal;
                }
                &::after{
                    content: "";
                    @include position(absolute, 20px, auto, 0, 0);
                    right: 20px;
                    border-top: solid 4px #5e6267;
                    border-bottom: solid 4px transparent;
                    border-left: solid 4px transparent;
                    border-right: 4px solid transparent;
                }
                .filter-dropdown{
                    @media (max-width: 767px) {
                        right: inherit;
                    }
                    ul{
                        li{
                            .form-check{
                                input{
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .button{
            min-width: 150px;
            font-size: $font-size-12;
            padding: 11px;
            margin-top: 15px;
            @media (max-width: 420px) {
                margin: 0 auto;
                display: block;
            }
        }
    }
    .price-btn{
        @include size(tablet){
            padding: 7px 11px;
        }
        @media (max-width: 767px) {
            width: auto;
        }
    }
}
.list-item{
    box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
    background: $white-clr;
    padding: 20px;
    border-radius: $input-radius;
    margin: 10px 0;
    @include transition(all ease-in-out 0.2s);
    cursor: pointer;
    &:hover{
        @include transition(all ease-in-out 0.2s);
        box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.2);
    }
    .location{
        margin-bottom: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 98%;
        display: block;
        padding: 2px 0;
        i{
            width: 25px;
            height: 25px;
            background: #f4f4f4;
            @include flexM(inline-flex, center, center);
            border-radius: $input-radius;
            margin-right: 10px;
            position: relative;
            top: 3px;
        }
        @include font($font-size-md, normal, $primary-txt-clr);
    }
    .image{
        position: relative;
        margin-bottom: 20px;
        .featured-tag{ 
            position: absolute;
            top: 10px;
            left: -10px;
            border-radius: 2.5px;
            font-size: 11px;
            font-weight: 700;
            text-transform: uppercase;
            background-color: #b53ef0;
            padding: 6.5px 11px;
            color: #ffffff;
            z-index: 1;
        }
        img{
            width: 100%;
            height: 160px;
            object-fit: cover;
            border-radius: $input-radius;
            @include size(tablet){
                height: 100px;
            }
            @include size(tablet-up){
                height: 160px;
            }
        }
        .txt{
            @include position(absolute, auto, 0, auto, 100%);
            padding: 10px;
            bottom: 0;
            justify-content: space-between;
            display: flex;
            .price-btn{
                width: auto;
                background: rgba(33, 35, 38,  0.8);
                padding: 7px;
                max-width: 49.9%;
            }
        }
    }
    h5{
        @include font($font-size-p, $med-font, $primary-txt-clr);
        margin-bottom: 5px;
        line-height: 1.47;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 98%;
    }
    p{
        @include font($font-size-13, normal, $gray-color);
        line-height: 1.54;
    }
    .status-btn{
        width: 100%;
        margin: 25px 0 10px 0;
        position: relative;
        overflow: hidden;
        z-index: 1;
        @include transition(all ease-in-out 0.3s);
        @include size(tablet){
            padding: 9px 15px;
        }
        &::after{
            content: "";
            @include position(absolute, 0, 0, 100%, 0);
            @include gradient(to right, $gradient-color);
            right: 0;
            bottom: 0;
            z-index: -1;
            @include transition(all ease-in-out 0.3s);
        }
        &:hover{
            color: $white-clr;
            @include transition(all ease-in-out 0.3s);
            &:after{
                width: 100%;
                @include transition(all ease-in-out 0.3s);
            }
        }
    }
}
.our-universe-section{
    position: relative;
    padding-bottom: 40px;
    background: $light-gray;
    &::after{
        content: "";
        @include position(absolute, auto, 0, 420px, 100%);
        @include gradient(to right, $gradient-color);
        right: 0;
        bottom: 0;
        @media (max-width: 767px) {
            height: 600px;
        }
    }
    .container{
        z-index: 1;
        position: relative;
    }
    .heading-sec{
        padding: 60px 0 50px 0;
        text-align: center;
        @media (max-width: 767px) {
            padding-bottom: 35px;
        }
        h6{
            @include font($font-size-12, $med-font, $primary-light-clr);
            text-transform: $capital;
            position: relative;
            padding-top: 15px;
            margin-bottom: 30px;
            @media (max-width: 767px) {
                letter-spacing: 3px;
                margin-bottom: 20px;
            }
            &::after{
                content: "";
                @include position(absolute, 0, 0, 2px, 30px);
                background: $primary-light-clr;
                right: 0;
                margin: 0 auto;
            }
        }
        p{
            @include font($font-size-22, normal, $primary-txt-clr);
            line-height: 2.05;
            max-width: 750px;
            margin: 0 auto;
            @include size(tablet){
                font-size: $font-size-18;
            }
            @media (max-width: 767px) {
                font-size: $font-size-15;
                line-height: 2;
            }
        }
    }
    .video-wrp {
        width: 100%;
        margin: 0 auto 40px auto;
        position: relative;
        border-radius: $input-radius;
        @media (max-width: 767px) {
            height: 400px;
            overflow: hidden;
            margin-bottom: 10px;
        }
        .play-pause-btn{
            width: 70px;
            height: 70px;
            @include gradient(45deg, $gradient-color);
            border-radius: 50%;
            @include flexM(flex, center, center);
            img{
                width: auto;
            }
        }
        .text{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            @include flexM(flex, center, flex-end);
            flex-flow: column;
            h4{
                @include font($font-size-xl, normal, $white-clr);
                max-width: 750px;
                text-align: center;
                line-height: 1.5;
                margin: 110px 0 60px 0;
                @include size(tablet){
                    margin: 50px 0 30px 0;
                    font-size: $font-size-22;
                }
                @media (max-width: 767px) {
                    font-size: $font-size-18;
                    line-height: 1.67;
                    margin: 100px 20px 30px;
                }
            }
        }
        img{
            width: 100%;
            @media (max-width: 767px) {
                object-fit: cover;
                height: 100%;
            }
        }
        .video-player{
            height: 600px !important;
            @media (max-width: 767px) {
                height: 400px !important;
            }
        }
        &.sm-video{
            @include size(tablet){
                height: 200px !important;
            }
            @include size(phone){
                height: 180px !important;
                border-radius: 0;
            }
            .video-player{
                @include size(tablet){
                    height: 200px !important;
                }
                @include size(phone){
                    height: 180px !important;
                }
            }
        }
    }
    .white-widget{
        background: $white-clr;
        border-radius: $input-radius;
        padding: 20px;
        height: 100%;
        display: flex;
        @include size(tablet){
            margin-top: 10px;
            height: auto;
        }
        @media (max-width: 767px) {
            margin-top: 10px;
            height: auto;
        }
        .icon{
            width: 40px;
            height: 40px;
            border-radius: $input-radius;
            background: $published-bg-clr;
            margin-right: 20px;
            @include flexM(flex, center, center);
        }
        .content{
            width: calc(100% - 60px);
            h5{
                @include font($font-size-md, $heading-font, $primary-txt-clr);
                margin-bottom: 5px;
            }
            p{
                @include font($font-size-13, normal, $gray-color);
                line-height: 1.54;
            }
        }
    }
}
.become-host-section{
    background: $light-gray;
    padding: 100px 0;
    @include size(tablet){
        padding: 60px 0;
    }
    @media (max-width: 767px) {
        padding: 50px 0;
    }
    .add-pad{
        padding: 0 40px;
        @include size(tablet){
            padding: 0;
        }
        @media (max-width: 767px) {
            padding: 0;
        }
        .v-center{
            @include flexM(flex, center, flex-start);
            .magnta-btn{
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
        .text{
            @include size(tablet){
                margin-top: 30px;
            }
            h4{
                @include font($font-size-lg, normal, $primary-txt-clr);
                line-height: 1.8;
                margin-bottom: 6px;
                @include size(tablet){
                    font-size: $font-size-20;
                }
                @media (max-width: 767px) {
                    font-size: $font-size-18;
                    line-height: 1.6;
                    margin-bottom: 10px;
                }
            }
            p{
                @include font($font-size-h3, 300, $gray-color);
                line-height: 2.06;
                margin-bottom: 30px;
                @include size(tablet){
                    font-size: $font-size-16;
                    margin-bottom: 20px;
                }
                @media (max-width: 767px) {
                    font-size: $font-size-14;
                }
            }
        }
        .img{
            @media (max-width: 767px) {
                margin-bottom:20px;
            }
            img{
                width: 100%;
            }
        }
    }
}
.exp-acco-section{
    padding: 40px 0;
    @include gradient(to right, $gradient-color);
    @media (max-width: 767px) {
        padding: 50px 0;
    }
    .text{
        h4{
            @include font($font-size-xl, normal, $white-clr);
            margin-bottom: 10px;
            @include size(tablet){
                font-size: $font-size-lg;
            }
            @media (max-width: 767px) {
              font-size: $font-size-20;
            }
        }
        p{
            @include font($font-size-p, 300, $white-clr);
            line-height: 1.73;
            max-width: 715px;
            opacity: 0.8;
            @media (max-width: 767px) {
              font-size: $font-size-15;
              line-height: 1.87;
              margin-bottom: 20px;
            }
        }
    }
    .align-right{
        @include flexM(flex, center, flex-end);
        height: 100%;
        @media (max-width: 767px) {
          justify-content: flex-start;
        }
        .btn{
          @media (max-width: 767px) {
            width: 100%;
          }
        }
    }
}
.footer-sec{
    background: $white-clr;
    padding: 70px 0;
    border-bottom: 4px solid $primary-light-clr;
    @media (max-width: 767px){
        padding: 4px 0 50px 0;
    }
    .flex-end{
        @include flexM(flex, baseline, flex-end);
        @media (max-width: 767px){
            justify-content: flex-start;
            width: 100%;
        }
    }
    .foot-item{
        @media (max-width: 767px){
            margin-top: 36px;
            width: 100%;
        }
        img{
            margin-bottom: 15px;
            @include size(tablet){
                max-width: 150px;
            }
        }
        p{
            @include font($font-size-12, normal, $gray-color);
            line-height: 1.67;
        }
        h4{
            @include font($font-size-13, $heading-font, $primary-txt-clr);
            position: relative;
            img{
              @media (max-width: 767px) {
                position: absolute;
                top: -5px;
                right: 0;
                cursor: pointer;
                @include transition(all ease-in-out 0.2s);
              }
            }
            &.active{
              img{
                @media (max-width: 767px) {
                  transform: rotate(180deg);
                  @include transition(all ease-in-out 0.2s);
                }
              }
            }
        }
        ul{
          margin-top: 20px;
          @media (max-width: 767px) {
            display: none;
          }
          li{
              list-style: none;
              margin-bottom: 30px;
              @include size(tablet){
                margin-bottom: 20px;
              }
              a{
                  @include font($font-size-p, normal, $gray-color);
                  cursor: pointer;
              }
              &:last-of-type{
                  margin-bottom: 0;
              }
          }
          &.active{
            @media (max-width: 767px) {
              display: block;
            }
          }
          &.social-links{
            &.active{
                @media (max-width: 767px) {
                  display: flex;
                }
              }
          }
        }
        .j-align-center{
            @media (max-width: 767px) {
                display: flex;
                justify-content: space-between;
            }
        }
        .lawyerd-logo{
            display: block;
            margin: 10px 0 70px 0;
            @media (max-width: 767px) {
                display: inline-flex;
                margin: 0;
            }
            img{
                max-width: 116px;
                width: 100%;
                @media (max-width: 767px) {
                    width: 96px;
                    object-fit: contain;
                }
            }
        }
    }
    .social-links{
        display: flex;
        flex-flow: row wrap;
        li{
            margin: 0 10px 0 0 !important;
            a{
                display: flex;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                @include flexM(flex, center, center);
                @include transition(all ease-in-out 0.2s);
                img{
                    margin-bottom: 0;
                    max-width: 28px;
                    display: inline-flex;
                    @include transition(all ease-in-out 0.2s);
                }
                &:hover{
                    background-color: #9b9b9b;
                    @include transition(all ease-in-out 0.2s);
                    img{
                        filter: brightness(2);
                        @include transition(all ease-in-out 0.2s);
                    }
                }
            }
        }
    }
}