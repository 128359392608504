.form-container{
    border-radius: 0.625rem;
    background-color: $white-clr;
    margin: 1.875rem 1.875rem 1.875rem 0;
    padding: 3.125rem 2.375rem;
    @media (max-width: 767px) {
        padding: 30px 10px 20px 10px;
        margin: 0 10px 20px;
    }
    &.for-admin{
        margin: 0;
        border-radius: 0;
        padding: 3.125rem;
        min-height: calc(100vh - 71px);
        background: none;
    }
    &.review-detail-pg{
        margin:1.875rem  0;
    }
    .heading{
        h1{

            @include font($font-size-lg, $heading-font, $primary-txt-clr);
            margin-bottom: 10px;
        }
        p{
            @include font($font-size-p, $reg-font, $gray-color);
            max-width: 450px;
            line-height: 1.73;
            @include size(phone){
                max-width: inherit;
            }
        }
    }
    .switch-block{
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: $input-radius;
        padding: 1.25rem;
        &.light-gray{
            background-color: rgba(94, 98, 103, 0.05);
        }
        h3{
            @include font($font-size-h3, normal, $primary-txt-clr);
            margin-bottom: 5px;
        }
        p{
            @include font($font-size-p, normal, $gray-color);
            max-width: inherit;
        }
        h6{
            @include font(12px, normal, $gray-color);
            margin-bottom: 5px;
        }
        strong{
            @include font($font-size-p, $med-font, $primary-txt-clr);
            @include flexM(flex, center, start);
            height: 100%;
            max-height: 38px;
        }
        .flex-end{
            @include flexM(flex, center, flex-end);
        }
        .b-clr{
            color: $primary-light-clr;
        }
        .white-block {
            background: $white-clr;
            padding: 20px;
            border-radius: $input-radius;
            box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, .05);
            margin: 30px 0 10px 0;
            &.mob-ui{
                @media (max-width: 767px) {
                    margin: 30px -20px -20px -20px;
                }
            }
            &.less-mr{
                margin: 10px 0;
                padding: 15px 20px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
            .custom-row {
                display: flex;
                margin-bottom: 20px;
                &:last-of-type{
                    margin-bottom: 0;
                }
                @media (max-width: 767px) {
                    flex-flow: column;
                }
                .custom-col-5 {
                    flex: 1;
                    &:first-of-type{
                        margin-right: 30px;
                        @media (max-width: 767px) {
                            margin-right: 0;
                        }
                    }
                }
                .custom-col-2 {
                    display: flex;
                    width: 65px;
                    margin-left: 20px;
                    @media (max-width: 767px) {
                        width: 100%;
                        margin-left: 0;
                    }
                    .delete-btn{
                        @media (max-width: 767px) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        .auto-w-box{
            &.bx-w-sm{
                .white-block{
                    min-width: 140px;
                    @media (max-width: 767px) {
                        min-width: auto;
                        padding: 20px 0 20px 20px;
                        margin-right: 10px;
                        width: 48%;
                    }
                    @media (max-width: 370px) {
                        width: 100%;
                    }
                }
            }
            .white-block{
                min-width: 160px;
                display: inline-flex;
                margin: 20px 20px 0 0;
                @media (max-width: 767px) {
                    min-width: auto;
                    width: 47%;
                    margin-right: 15px;
                    &:nth-child(even){
                        margin-right: 0;
                    }
                }
                @media (max-width: 370px) {
                    width: 100%;
										margin-right: 0;
                }
								&.add-pad-tb{
									padding: 25px 20px;
								}
            }
            .other-input{
                display: inline-flex;
								min-width: 160px;
								margin-top: 20px;
								@media (max-width: 767px) {
									min-width: auto;
									width: 47%;
								}
								@media (max-width: 370px) {
									width: 100%;
								}
            }
						.min-w-118{
							.white-block{
								@media (max-width: 1300px) {
									min-width: 118px;
								}
                                @media (max-width: 1200px) {
                                    min-width: 95px;
                                    padding: 25px 17px;
								}
							}
						}
        }
        &.strach-checkbox{
            .white-block{
                padding: 25px 20px;
            }
        }
        &.rooms{
            @media (max-width: 767px) {
                padding: 0.938rem;
            }
            .form-floating{
                margin-bottom: 0;
            }
            .white-block{
                &.less-mr{
                    padding: 15px 10px 15px 20px;
                }
            }
            .error-message{
                margin: 10px 0 0 0;
            }
        }
        .heading{
            margin-bottom: 0;
        }
        &.mx-wid-inherit{
            .heading{
                p{
                    max-width: inherit;
                }
            }
        }
        .sync-btn{
            cursor: pointer;
        }
    }
    .upload-img-list{
        display: flex;
        flex-flow: row wrap;
        margin-top: 0;
        .img-item{
            display: inline-flex;
            position: relative;
            border-radius: $input-radius;
            overflow: hidden;
            background: $black-color;
            margin-top: 20px;
            .delete-ic{
                position: absolute;
                top: 6px;
                right: 10px;
                z-index: 1;
                img{
                    cursor: pointer;
                    height: auto;
                }
            }
        }
    }
    .white-block-l{
        background: $white-clr;
        padding: 30px;
        border-radius: $input-radius;
        box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, .05);
        @media (max-width: 767px) {
            padding: 20px;
        }
        &.lease-signor {
            .heading{
                display: flex;
                flex-flow: column;
                justify-content: center;
                height: 100%;
                h3{
                    @include font($font-size-h3, normal, $primary-txt-clr);
                }
            }
            .delete-btn{
                max-width: 65px;
                height: 65px;
            }
            .align-right {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    .full-width {
        width: 100%;
        text-transform: $capital;
        background: $light-gray;
        border: none;
        border-radius: 10px;
        padding: 14.5px;
        @include font($font-size-md, $semi-font, $primary-txt-clr);
    }
    .thumb-item{
        @include flexM(flex, center, center);
        position: relative;
        &.big-img{
            height: 100%;
            img{
                max-height: 482px;
                height: 100%;
                object-fit: cover;
            }
        }
        img{
            width: 100%;
            border-radius: $input-radius;
            max-height: 230px;
            object-fit: cover;
        }
        a{
            @include position(absolute, 0, 0, 100%, 100%);
            @include flexM(flex, center, center);
            color: $white-clr;
            background: rgba(0, 0, 0, 0.6);
            cursor: pointer;
            border-radius: $input-radius;
            &:hover{
                color: $white-clr;
                text-decoration: underline;
            }
        }
        .simple-rating{
            @include position(absolute, auto, 20px, auto, auto);
            background: $primary-txt-clr;
            @include font($font-size-13,  $heading-font, $white-clr);
            bottom: 20px;
            padding: 17px 20px;
            text-transform: $capital;
            border-radius: $input-radius;
        }
        .rating{
            @include position(absolute, auto, 20px, auto, auto);
            @include gradient(to left, $succ-gradient-color);
            @include font($font-size-13,  $heading-font, $white-clr);
            bottom: 20px;
            padding: 17px 20px;
            border-radius: $input-radius;
            @include flexM(flex, center, flex-start);
            width: 355px;
            overflow: hidden;
            .img{
                margin-right: 10px;
            }
            .txt{
                p{
                    @include font($font-size-13, 300, $white-clr);
                    opacity: 0.8;
                    margin-bottom: 3px;
                }
                h4{
                    @include font($font-size-p, $semi-font, $white-clr);
                }
            }
            .rate{
                margin: 0 20px;
                h3{
                    @include font($font-size-22, $e-bold-font, $white-clr);
                }
            }
            .bton{
                @include position(absolute, 0, auto, 100%, 60px);
                @include flexM(flex, center, center);
                right: 0;
                background: rgba(33, 35, 38, 0.2);
            }
        }
    }
    .detail-heading{
        h2{
            @include  font($font-size-20, $heading-font, $black-color);
            margin-bottom: 10px;
        }
        p{
            @include  font($font-size-md, normal, $gray-color);
            @media (max-width: 767px) {
                margin-bottom: 20px;
                line-height: 22px;
            }
        }
        .align-right {
            @include flexM(flex, center, flex-end);
            height: 100%;
            flex-flow: row wrap;
            @media (max-width: 560px) {
                flex-flow: row wrap;
            }
            .btn{
                margin-bottom: 10px;
            }
            .week-btn{
                background: rgba(33, 35, 38, 0.8);
                border-radius: $input-radius;
                border: none;
                margin-right: 10px;
                @include  font($font-size-sm, normal, $white-clr);
                padding: 7px 17px;
                cursor: auto;
                b{
                    @include  font($font-size-p, $e-bold-font, $white-clr);
                }
                @media (max-width: 560px) {
                    margin-bottom: 10px;
                }
            }
            @media (max-width: 767px) {
                justify-content: flex-start;
            }
        }
    }
    .des-block, .block-out, .lease-signor-block{
        h3{
            @include  font($font-size-h3, normal, $primary-txt-clr);
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        h5{
            @include  font($font-size-md, normal, $gray-color);
            margin-bottom: 5px;
        }
        p{
            @include  font($font-size-md, normal, #171a1d);
            line-height: 30px;
            word-break: break-word;
            &.verified{
                color: $succ-clr;
            }
            a{
                text-decoration: underline;
            }
        }
        @media (max-width: 767px) {
            &.mr-b-40{
                margin-bottom: 0;
            }
        }
        @media (max-width: 767px) {
            .table-item{
                margin-bottom: 20px;
            }
        }
        &.white-block{
            background: $white-clr;
            border-radius: $input-radius;
            padding: 20px;
            .align-right{
                @include flexM(flex, center, flex-end);
                height: 100%;
            }
        }
    }
    .lease-signor-block{
        background: rgba(0, 0, 0, 0.02);
        border-radius: $input-radius;
        &.admin-lease-signor.white-bg{
            .add-pad{
                .row{
                    .col-md-2{
                        &:last-child{
                            @media (min-width: 768px){
                                width: 11%; 
                            }
                        }
                        @media (min-width: 768px){
                            width: 18.3% 
                        }
                    }
                }
            }
        }
        .add-pad{
            padding: 30px;
            @media (max-width: 767px){
                padding: 20px;
            }
            .row{
                .col-md-2:nth-child(2),
                .col-md-2:nth-child(4){
                    @media (min-width: 768px){
                        width: 14.5%;
                    }
                }
                .col-md-2:nth-child(5),
                .col-md-2:nth-child(6){
                    @media (min-width: 768px){
                        width: 12.5%;
                    }
                }
                .col-md-2:nth-child(7){
                    @media (min-width: 768px){
                        width: 12.5%;
                    }
                }
            }
        }
        .heading {
            padding: 30px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            display: flex;
            margin-bottom: 0;
            @media (max-width: 767px) {
                padding: 20px;
            }
            .user-thumb{
              background: rgba(33, 35, 38, 0.1);
              min-width: 40px;
              height: 40px;
              @include flexM(flex, center, center);
              margin-right: 20px;
              border-radius: $input-radius;
            }
            .title{
                h4{
                    @include  font($font-size-15, $med-font, $primary-txt-clr);
                }
                p{
                    @include  font($font-size-13, normal, $gray-color);
                    line-height: 30px;
                    max-width: 100%;
                }
            }
            &.mr-b-0{
                margin-bottom: 0;
            }
            &.pad-t-b-20{
                padding: 20px 30px;
            }
        }
        &.white-bg{
            background: $white-clr;
            .header-block{
                padding: 30px;
                position: relative;
                &::after{
                    content: "";
                    @include position(absolute, 100%, 30px, 1px, calc(100% - 60px));
                    background: rgba(0, 0, 0, 0.05);
                }
                .heading{
                    border-bottom: 0;
                    padding: 0;
                }
                .sync-btn {
                    cursor: pointer;
                }
            }
            .border-btm{
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                padding: 30px 0;
                .table-item{
                    p{
                        @include font($font-size-p, $med-font, $primary-txt-clr);
                        &.status{
                            color: $succ-clr;
                            &.pendding{
                                color: $error-clr;
                            }
                            &.make-default{
                                text-decoration: underline;
                                color: $primary-light-clr;
                                cursor: pointer;
                            }
                        }
                        a{
                            text-decoration: underline;
                        }
                    }
                }
            }
            .flex-end{
                @include flexM(flex, center, flex-end);
                @media (max-width: 767px) {
                    flex-flow: row wrap;
                }
            }
            .radio-btn{
                @include flexM(flex, center, flex-start);
                height: 100%;
            }
            .divider{
                @media (max-width: 767px) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                }
            }
        }
    }
    &.min-height{
        padding: 0;
        background: $light-gray;
        .owner-body{
            padding: 50px 38px;
            min-height: calc(100vh - 133px);
            @media (max-width: 767px) {
                padding: 30px 8px;
            }
        }
        .owner-footer{
            background: rgba(33, 35, 38, 0.05);
            padding: 28px;
            @media (max-width: 767px) {
                padding: 28px 0px;
            }
            p{
                @include font($font-size-md, normal, $gray-color);
                @media (max-width: 767px) {
                    font-size: $font-size-13;
                    text-align: center;
                }
            }
            .hide-mob{
                .align-right{
                    @include flexM(flex, center, flex-end);
                    .owner-nav{
                        display: flex;
                        li{
                            list-style: none;
                            margin-left: 30px;
                            &:first-of-type{
                                margin-left: 0;
                            }
                            a{
                                @include font($font-size-p, normal, $gray-color);
                                cursor: pointer;
                            }
                        }
                    }
                }
                @media (max-width: 767px) {
                    display: none;
                }
            }
        }
    }
    .button-wrp{
        button, a{
            &.btn-primary{
                @media (max-width: 767px) {
                    margin: 0 0 15px 0;
                }
            }
            &:nth-child(1){
                @media (max-width: 767px) {
                    order: 2;
                }
            }
            &:nth-child(2){
                @media (max-width: 767px) {
                    order: 1;
                }
            }
        }
    }
}
.dashboard-page{
    background: $light-gray;
    .dash-body{
        padding: 3.125rem;
        min-height: 950px;
        
        .total-count-sec{
            .total-item{
                padding: 24px 30px;
                border-radius: $input-radius;
                @include gradient(79deg, $total-booking-color);
                &.search{
                    @include gradient(to left, $total-search-color);
                }
                p{
                    @include font($font-size-md, 300, $white-clr);
                    opacity: 0.7;
                }
                h6{
                    @include font($font-size-20, $heading-font, $white-clr);
                }
            }
        }
        .card-sec{
            .card-item{
                position: relative;
                overflow: hidden;
                .card-head{
                    padding: 50px 30px;
                    border-radius: $input-radius;
                    background: rgba(181, 62, 240, 0.05);
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    position: relative;
                    img{
                        position: absolute;
                        bottom: -34px;
                        right: -50px;
                    }
                    strong{
                        @include font($font-size-p, $heading-font, $primary-light-clr);
                        margin-bottom: 5px;
                    }
                    h2{
                        @include font($font-size-xl, $heading-font, $primary-light-clr);
                    }
                    &.payouts{
                        background: rgba(108, 152, 239, 0.05);
                        h2{
                            color: $blue-clr;
                        }
                        strong{
                            color: $blue-clr;
                        }
                    }
                    &.profits{
                        background: rgba(94, 98, 103, 0.05);
                        h2{
                            color: $gray-color;
                        }
                        strong{
                            color: $gray-color;
                        }
                    }
                    &.deposit{
                        background: rgba(87, 176, 127, 0.05);
                        h2{
                            color: $succ-clr;
                        }
                        strong{
                            color: $succ-clr;
                        }
                    }
                    &.cancel{
                        background: rgba(239, 44, 44, 0.05);
                        h2{
                            color: $error-clr;
                        }
                        strong{
                            color: $error-clr;
                        }
                    }
                }
                .card-bodi{
                    background: $white-clr;
                    border-radius: $input-radius;
                    padding: 9px 30px;
                    margin-top: -15px;
                    position: relative;
                    .card-list{
                        padding: 22px 0;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid rgba(94, 98, 103, 0.05);
                        &:last-of-type{
                            border-bottom: none;
                        }
                        p{
                            width: 50%;
                            @include font($font-size-md, normal, $gray-color);
                        }
                        b{
                            width: 50%;
                            @include font($font-size-18, $heading-font, $primary-txt-clr);
                            text-align: end;
                        }
                    }
                }
            }
        }
        .pagination-wrp{
            .item-count{
                @include flexM(flex, center, center);
            }
        }
    }
    .dash-footer{
        background: rgba(33, 35, 38, 0.05);
        padding: 28px;
        p{
            @include font($font-size-md, normal, $gray-color);
            text-align: center;
        }
    }
}

// Listing managment CSS
.dash-body, .owner-body{
    .heading{
        margin-bottom: 3.125rem;
        position: relative;
        @media (max-width: 767px){
            margin-bottom: 1.875rem;
        }
        h2{
            @include font($font-size-lg, $heading-font, $black-color);
            @include flexM(flex, center, flex-start);
            margin-bottom: 5px;
            @media (max-width: 767px){
                font-size: $font-size-20;
            }
            .go-back{
                min-width: 40px;
                height: 40px;
                @include flexM(inline-flex, center, center);
                background: rgba(33, 35, 38, 0.05);
                border-radius: $input-radius;
                margin-right: 20px;
                cursor: pointer;
                a{
                    display: flex;
                }
            }
            span{
                p{
                    color: $primary-txt-clr;
                    margin-top: 5px;
                }
            }
        }
        p{
            @include font($font-size-p, normal, $gray-color);
            line-height: inherit;
            @media (max-width: 767px){
                font-size: $font-size-13;
            }
        }
        .search-list{
            border: none;
            background: rgba(33, 35, 38, 0.05);
            @include font(13px, normal, $gray-color);
            padding: 10px 20px;
            border-radius: $input-radius;
            &:focus, &:hover{
                -moz-box-shadow: 0 0 0 1px rgba(105, 41, 139, 1);
                box-shadow: 0 0 0 1px rgba(105, 41, 139, 1);
                -webkit-box-shadow: 0 0 0 1px rgba(105, 41, 139, 1);
              }  
        }
        .align-center{
            @include flexM(flex, center, center);
            height: 100%;
            @media (max-width: 767px) {
                margin-top: 20px;
                height: auto;
            }
        }
        .align-right{
            @include flexM(flex, center, flex-end);
            height: 100%;
            .status-btn{
                cursor: auto;
                &.switch-role{
                    cursor: pointer;
                }
            }
            @media (max-width: 767px) {
                margin-top: 20px;
                height: auto;
            }
            &.mr-t-0{
                @media (max-width: 767px) {
                    margin-top: 0;
                    height: 100%;
                }
            }
            .mr-l-20{
                .view-list{
                    margin-left: 0;
                }
            }
            .view-list{
                @include font(12px, normal, $white-clr);
                @include gradient(to right, $gradient-color);
                border: none;
                margin-left: 20px;
                text-transform: $capital;
                padding: 11px 15px;
                border-radius: $input-radius;
                width: 220px;
                @media (max-width: 767px){
                    padding: 8px;
                    margin-left: 15px;
                    width: auto;
                }
                &:hover{
                    color: #69298b;
                }
            }
            .filter-action{
                background: $white-clr;
                border: none;
                padding: 8px;
                z-index: 1;
            }
            .filter-list{
                position: relative;
                display: flex;
                margin-left: 20px;
                i{
                    background: $white-clr;
                    @include font($font-size-13, normal, $gray-color);
                    border-radius: $input-radius;
                    padding: 13px 40px 13px 20px;
                    cursor: pointer;
                    font-style: normal;
                    width: 150px;
                }
                &::after{
                    content: "";
                    @include position(absolute, 20px, auto, 0, 0);
                    right: 20px;
                    border-top: solid 4px #5e6267;
                    border-bottom: solid 4px transparent;
                    border-left: solid 4px transparent;
                    border-right: 4px solid transparent;
                }
                .filter-dropdown{
                    ul{
                        li{
                            .form-check{
                                input{
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
            .label{
                @include font($font-size-12, normal, $gray-color);
                margin: 0 15px;
                + .filter-list{
                    margin-right: 15px;
                }
            }
            .setting-icon{
                min-width: 40px;
                height: 40px;
                border-radius: $input-radius;
                background: rgba(33, 35, 38, 0.05);
                @include flexM(flex, center, center);
                margin-left: 15px;
                cursor: pointer;
                position: relative;
            }
            .align-center{
                min-width: 270px;
            }
        }
        .user-mang-filter{
            @include flexM(flex, center, flex-end);
            height: 100%;
            .align-center{
                width: 270px;
                &.mr-r-0{
                    margin-right: 0;
                }
            }
            &.sms-filter{
                .align-right{
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
                .form-floating{
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                    .form-select{
                        background-color: #e1e2e2;
                        padding: 11px 20px;
                        height: auto;
                        font-weight: 400;
                        font-size: 14px;
                    }
                    label{
                        display: none;
                    }
                }
            }
        }
        .user-list-pg{
            .align-center{
                min-width: 300px;
            }
        }
    }
}
.button-2{
    @include flexM(flex, center, flex-end);
    margin-top: 50px;
    .btn-primary{
        margin-left: 15px;
    }
    .button{
        @include  font($font-size-12, normal, $white-clr);
        border-radius: $input-radius;
        text-transform: $capital;
        padding: 11px 30px;
    }
    @include size(phone){
        flex-flow: row wrap;
    }
}
.dash-body{
    .container-fluid{
        padding: 0;
    }
}
.part-2{
    @media (max-width: 767px) {
       flex-flow: row wrap;
    }
    .title{
        width: 50%;
        @media (max-width: 767px) {
            width: 100%;
        }
        @include flexM(flex, center, flex-start);
        h4{
            @include font($font-size-18 !important, $bold-font-8 !important, $primary-txt-clr !important) ;
        }
    }
    .align-right{
       width: 50%;
       @media (max-width: 767px) {
        width: 100%;
        }
    }
}
// User Detail Widget 
.user-detail{
    background: $white-clr;
    border-radius: $input-radius;
    margin-bottom: 20px;
    padding: 30px;
    @include flexM(flex, center, center);
    flex-direction: column;
    @media (max-width: 767px) {
        padding: 20px;
    }
    .heading {
        display: flex;
        margin-bottom: 0;
        h5{
            @include font($font-size-18, $bold-font-8, $primary-txt-clr);
        }
        .user-thumb{
          background: rgba(33, 35, 38, 0.1);
          width: 40px;
          height: 40px;
          @include flexM(flex, center, center);
          margin-right: 20px;
          border-radius: $input-radius;
        }
        .title{
            h4{
                @include  font($font-size-h3, $semi-font, $primary-txt-clr);
            }
            p{
                @include  font($font-size-md, normal, $gray-color);
                line-height: 30px;
            }
        }
    }
    em{
        @include font($font-size-13, normal, $gray-color);
    }
    .user-info{
        @include flexM(flex, center, flex-start);
        &.row-wrap{
            flex-flow: row wrap;
            .img-item{
                margin-bottom: 10px;
            }
        }
        .img{
             max-width: 80px;
             margin-right: 20px;
             border-radius: $input-radius;
            overflow: hidden;
             @media (max-width: 767px) {
                 min-width: 60px;
                 margin-right: 15px;
             }
             img{
                 max-width: 100%;
                 object-fit: contain;
                 width: 80px;
                 height: 80px;
                 @media (max-width: 767px) {
                    width: 72px;
                    height: 72px;
                 }
             }
        }
        .info{
            h5{
                @include font($font-size-18, $semi-font, $primary-txt-clr);
            }
            p{
                @include font($font-size-md, normal, $gray-color);
                margin-top: 5px;
            }
            .change-pic{
                @include font($font-size-sm, normal, $white-clr);
                text-transform: $capital;
                border-radius: $input-radius;
                margin-bottom: 10px;
                min-width: 150px;
            }
        }
        &.edit-profile{
            .img{
                max-width: 120px;
                border-radius: $input-radius;
                overflow: hidden;
                img{
                    height: 120px;
                    object-fit: cover;
                    width: 120px;
                    @media (max-width: 767px) {
                        height: 60px;
                        width: 60px;
                    }
                }
                @media (max-width: 767px) {
                    min-width: 60px;
                    margin-right: 15px;
                    max-width: 80px;
                }
            }
        }
    }
    .address-detail{
        @include flexM(flex, inherit, center);
        flex-flow: column;
        height: 100%;
        h5{
            @include font($font-size-p, $semi-font, $primary-txt-clr);
        }
        p{
            @include font($font-size-md, normal, $gray-color);
            margin-bottom: 5px;
        }
    }
    .user-profession{
        @include flexM(flex, inherit, center);
        flex-flow: column;
        height: 100%;
        p{
            @include font($font-size-md, normal, $gray-color);
            margin-bottom: 5px;
        }
        span .profession-text{
            @include font($font-size-p, $semi-font, $primary-txt-clr);
            &.green-color{
                color: $succ-clr;
            }
        }
    }
    .table-head{
        margin-bottom: 0;
    }
    .table-body{
        border-bottom: 1px solid rgba(94, 98, 103, 0.05);
        padding: 20px;
        &.bdr-btm-n{
            border-bottom: none;
            margin-bottom: 0;
            padding: 0;
        }
        .table-col{
            padding: 0;
        }

    }
    .des-block{
        h4{
            @include  font($font-size-14, normal, $gray-color);
            margin-bottom: 5px;
        }
        h5{
            @include  font($font-size-12, normal, $gray-color);
        }
        p{
            @include  font($font-size-14, normal, $primary-txt-clr);
            line-height: 30px;
            word-break: break-word;
            &.green-color{
                color: $succ-clr;
            }
        }
        .des{
            padding-top: 20px;
            border-top: 1px solid rgba(94, 98, 103, 0.05);
        }
    }
    .align-right{
        @include flexM(flex, center, flex-end);
        height: 100%;
        @media (max-width: 767px) {
            a{
                width: 100%;
                .btn{
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
    }
}
.feature-your-list{
    padding: 30px;
    border-radius: $input-radius;
    @include gradient(to right, $total-booking-color);
    @include size(tablet-up){
        padding: 20px 5px;
    }
    .text{
        h2{
            @include font($font-size-18, $heading-font, $white-clr);
            margin-bottom: 8px;
        }
        p{
            @include font($font-size-md, normal, $white-clr);
            line-height: 25px;
        }
    }
    .align-right{
        @include flexM(flex, center, flex-end);
        height: 100%;
        @include size(tablet-up){
            flex-direction: column;
        }
        @include size(tablet-up){
            button{
                width: 100%;
                margin: 15px 0 0;
            }
            a{
                width: 100%;
            }
        }
    }
}
.ave-rating{
    background-color: $light-gray;
    padding: 20px;
    border-radius: $input-radius;
    margin-top: 20px;
    .txt{
        @include size(tablet-up){
          text-align: center;
        }
        h5{
            @include font($font-size-15, $med-font, $primary-txt-clr);
            display: inline-flex;
            margin-right: 20px;
            @include size(tablet-up){
              display: flex;
              margin-bottom: 15px;
              justify-content: center;
            }
        }
    }
    span{
        text-decoration: underline;
        @include font($font-size-12, $heading-font, $primary-light-clr);
        text-transform: $capital;
        cursor: pointer;
    }
    .align-right{
        @include size(tablet-up){
            justify-content: center;
            margin-top: 10px;
        }
    }
}
.eviction-images{
    display: flex;
    flex-flow: row wrap;
    .item{
        display: flex;
        max-width: 160px;
        margin-right: 20px;
        border-radius: $input-radius;
        overflow: hidden;
        img{
            max-width: 100%;
        }
        &:last-of-type{
            margin-right: 0;
        }
    }
}
.eviction-button{
    @include flexM(flex, center, flex-end);
    @include size(tablet-up){
        flex-flow: row wrap;
    }
    .button{
        min-width: 250px;
        margin-left: 20px;
        @include size(tablet-up){
            margin: 0 0 15px 0;
            width: 100%;
        }
    }
}
.listing-detail-pg{
    .thumb-item{
        img{
            max-height: inherit;
            &.big-thumb{
                height: 390px;
            }
            &.small-thumb{
                height: 185px;
                &:not([src]){
                    display: none;
                }
            }
        }
    }
}
.user-role-top-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: auto !important;
}
.lease-heading-style{
    .heading{
        h3{
            @include font(16px, normal, $black-color);
            line-height: 24px;
        }
    }
}
.form-container{
    &.create-bank-account{
        padding-bottom: 9.225rem;
        min-height: 100vh;
        @include size(tablet-up){
            padding-bottom: 20px;
        }
        .button-wrp{
            position: relative;
            top: 100px;
            @include size(tablet-up){
                top: 0;
            }
        }
    }
}