.menu-light-gray{
  .main-header{
    background: $white-clr;
    .right-navbar{
      .nav-links{
        > li{
          > a, > button{
            color: $primary-txt-clr;
            background: #e2e3e3;
            border-color: transparent;
          }
          &:first-of-type{
            a{
              img{
                filter: brightness(0);
              }
            }
          }
        }
      }
    }
  }
}
.search-result-page{
  background: #f0f0f0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  position: sticky;
  top: 98px;
  z-index: 111;
  @include size(tablet-up){
    border: none;
    z-index: 1111;
  }
  .search-filter{
    margin: 0;
    .custom-row{
      background: none;
      box-shadow: inherit;
      .column{
        padding: 32px 0;
        border: none;
        @include size(tablet-up){
          border-bottom: 1px solid rgba(94, 98, 103, 0.05);
          padding: 25px 20px;
        }
        &:first-of-type{
          .form-filed{
            max-width: 80%;
          }
        }
        &:nth-child(2), &:nth-child(3){
          width: calc(18% - 40px);
          margin-right: 40px;
        }
        &:last-of-type{
          @include size(tablet-up){
            border-bottom: none;
          }
        }
        .form-filed{
          &.reverse-col{
            label{
              order: 1;
            }
          }
          input, select{
            background-color: #f0f0f0;
          }
        }
        .action-btn{
          @include size(tablet-up){
            margin-top: 25px;
          }
        }
      }
    }
  }
}

.advanced-filter{
  padding: 25px 0;
  position: fixed;
  top: 209px;
  left: 0;
  right: 0;
  background: $light-gray;
  z-index: 88;
  @include size(tablet-up){
    padding: 0;
    z-index: 999;
  }
  .col{
    @include size(tablet-up){
      width: 100%;
      flex: auto;
    }
  }
  .form-filed{
    @include size(tablet-up){
      margin-bottom: 20px;
    }
    select{
      border: none;
      border-radius: $border-r-20;
      @include font($font-size-13, $med-font, $black-color);
      padding: 0.65rem 2.25rem 0.65rem 1.2rem;
      &:focus, &:hover{
        -moz-box-shadow: 0 0 0 1px rgba(105, 41, 139, 1);
        box-shadow: 0 0 0 1px rgba(105, 41, 139, 1);
        -webkit-box-shadow: 0 0 0 1px rgba(105, 41, 139, 1);
      }
    }
    .gray-bg{
      @include size(tablet-up){
        background-color: $white-clr !important;
        border-radius: $input-radius;
      }
      input{
        @include size(tablet-up){
          background-color: $white-clr;
        }
      }
    }
  }
  .submit-btn{
    .btn{
      width: 100%;
      border-radius: $border-r-20;
      @include font($font-size-13, $med-font, $primary-light-clr);
      background: #f4eef7;
      border: none;
      padding: 0.65rem 1.2rem;
      text-align: left;
      position: relative;
      img{
        position: absolute;
        top: auto;
        right: 10px;
      }
    }
  }
  .button{
    @include size(tablet-up){
      margin-top: 100px;
    }
  }
}
.listing-sec{
  padding: 50px 0 100px;
  @include size(tablet-up){
    padding: 0 0 60px;
  }
  .list-item{
    margin: 0 0 25px 0;
  }
  .map{
    border-radius: $border-r-20;
    overflow: hidden;
    margin-left: 16px;
    width: calc(100% - 16px);
    height: calc(100vh - 231px);
    @include size(tablet-up){
      margin-left: 0;
      height: 450px;
      width: 100%;
    }
    > div{
      width: 100% !important;
      height: 100% !important;
    }
  }
  .map-mob{
    position: sticky;
    top: 220px;
    @include size(tablet-up){
      display: none;
    }
    &.active{
      @include size(tablet-up){
        display: block;
      }
    }
  }
}
.list-heading{
  padding: 26px 0;
  h2{
    @include font($font-size-20, $heading-font, $primary-txt-clr);
  }
  .action-btn{
    @include flexM(flex, center, flex-end);
    button{
      border: none;
      background-color: #e9e9ea;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      @include flexM(flex, center, center);
      margin-left: 10px;
    }
  }
}
.wrp-for-mob{
  @include size(tablet-up){
    display: none;
    background: $white-clr;
    @include position(fixed, 0, 0, 100%, 100%);
    z-index: 999;
    overflow: auto;
  }
  &.bg-gray{
    @include size(tablet-up){
      background-color: #f0f0f0;
    }
  }
  &.active{
    @include size(tablet-up){
      display: flex;
      flex-flow: column;
    }
  }
}
.search-result{
  .button{
    padding: 10.5px;
    font-size: $font-size-13;
  }
}
// Property Listing Request Modal style
.list-request{
  .modal-dialog{
    max-width: 700px;
  }
  .modal-box{
    padding: 40px;
    @include size(tablet-up){
      padding: 15px;
    }
    .header{
      h3{
        font-weight: $e-bold-font;
      }
    }
  }
  .user-information{
    background-color: $light-gray;
    border-radius: $border-r-4;
    padding: 0 20px 36px;
    margin-bottom: 20px;
    @include size(tablet-up){
      padding: 0 15px 36px;
    }
    h5{
      @include font($font-size-15, $med-font, $color-4a);
      margin: 26px 0;
    }
    .item{
      @include size(tablet-up){
        margin-bottom: 0;
      }
      h6{
        @include font($font-size-12, normal, $gray-color);
        margin-bottom: 4px;
      }
      p{
        @include font($font-size-15, normal, $primary-txt-clr);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 98%;
        @include size(tablet-up){
          margin-bottom: 15px;
        }
      }
    }
  }
  .btn-wrp{
    @include flexM(flex, center, flex-end);
    @include size(tablet-up){
      flex-flow: column;
    }
    .button{
      width: 150px;
      margin-left: 15px;
      @include size(tablet-up){
        width: 100%;
        margin: 15px 0 0;
      }
    }
  }
}