.payment-sec{
  padding: 40px 0 100px;
}
.payment-method{
  border-radius: $border-r-4;
  box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
  background-color: $white-clr;
  @include size(tablet-up){
    margin-bottom: 40px;
  }
  .header{
    padding: 30px 50px 42px;
    @include size(tablet-up){
      padding: 20px 15px;
    }
    h4{
      @include font($font-size-16, $heading-font, $primary-txt-clr);
      line-height: 24px;
    }
    p{
      @include font($font-size-14, normal, $gray-color);
      line-height: 24px;
    }
  }
  .body{
    background-color: $light-gray;
    padding: 32px 22px 26px 40px;
    @include size(tablet-up){
      padding: 20px 15px;
    }
    h5{
      @include font($font-size-15, normal, $primary-txt-clr);
      margin-bottom: 25px;
    }
    .pay-form{
      background-color: $white-clr;
      padding: 28px 20px 7px;
      border-radius: $input-radius;
    }
  }
  .footer{
    padding: 40px 60px 40px 40px;
    @include size(tablet-up){
      padding: 20px 15px;
    }
    h4{
      @include font($font-size-16, $heading-font, $primary-txt-clr);
      line-height: 24px;
    }
    p{
      @include font($font-size-14, normal, $gray-color);
      line-height: 24px;
    }
    a{
      @include font($font-size-14, normal, $primary-light-clr);
      line-height: 24px;
      text-decoration: underline;
    }
  }
}
.booking-summary{
  border-radius: $border-r-4;
  box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
  background-color: $white-clr;
  margin-bottom: 20px;
  @include size(tablet){
    margin-bottom: 30px;
  }
  .header{
    padding: 21px 31px 30px;
    @include size(tablet-up){
      padding: 20px 15px;
    }
    h4{
      @include font($font-size-16, $heading-font, $primary-txt-clr);
      line-height: 24px;
    }
  }
  .pricing{
    padding: 26px 30px 0;
    @include size(tablet-up){
      padding: 20px 15px 0;
    }
    h3{
      @include font($font-size-15, $heading-font, $black-color);
      margin: 5px 0 10px;
      border-top: 1px solid #e3e3e3;
      padding-top: 20px;
    }
    .item{
      padding: 0;
      border-bottom: 0;
      p{
        padding: 10px 0;
        @include font($font-size-13, $med-font, $black-color);
        line-height: normal;
      }
      &.total{
        border-top: 1px solid #e3e3e3;
        margin-top: 10px;
        p{
          @include font($font-size-15, $med-font, $black-color);
          padding: 15px 0;
        }
      }
      .flex-end{
        @include flexM(flex, center, flex-end);
      }
    }
  }
}
.booking-info-txt{
  border-radius: 4px;
  border: dashed 1px rgba(239, 44, 44, 0.4);
  background-color: rgba(239, 44, 44, 0.05);
  padding: 15px;
  margin-bottom: 20px;
  p{
    margin-top: 0;
    font-weight: 600;
    @include size(tablet-up){
      margin: 0;
    }
  }
}
.booking-item{
  background-color: $light-gray;
  padding: 27px 32px 32px;
  @include size(tablet-up){
    padding: 20px 15px;
  }
  .item{
    .img{
      img{
        width: 100%;
        border-radius: $input-radius;
        max-height: 90px;
        object-fit: cover;
        @include size(tablet-up){
          max-height: 150px;
          margin-bottom: 15px;
        }
      }
    }
    .text{
      h6{
        @include font($font-size-14, $heading-font, $primary-txt-clr);
        line-height: 24px;
      }
      p{
        @include font($font-size-13, normal, $gray-color);
        line-height: 24px;
      }
      span{
        @include font($font-size-13, normal, $primary-txt-clr);
        display: flex;
      }
      a{
        @include font($font-size-14, normal, $primary-light-clr);
        line-height: 24px;
        text-decoration: underline;
      }
    }
  }
}

.total-earning{
  .total-item{
      padding: 35px;
      border-radius: $input-radius;
      @include gradient(79deg, $total-booking-color);
      @include size(tablet-up){
        padding: 20px;
      }
      &.search{
          @include gradient(to left, $total-search-color);
      }
      p{
          @include font($font-size-17, $med-font, $white-clr);
          opacity: 0.7;
          margin-bottom: 15px;
          @include size(tablet-up){
            font-size: $font-size-14;
            margin-bottom: 10px;
          }
      }
      h6{
          @include font($font-size-xl, $bold-font, $white-clr);
          @include size(tablet-up){
            font-size: $font-size-20;
          }
      }
      &.mob-mr-b-20{
        @include size(tablet-up){
          margin-bottom: 20px;
        }
      }
  }
}

.lease-agreement{
  background-color: $white-clr;
  padding: 40px 30px;
  border-radius: 4px;
  @include size(tablet-up){
    margin-bottom: 20px;
  }
  .text{
    height: 700px;
    overflow: auto;
    h1{
      @include font($font-size-20, 600, $black-color);
      line-height: 30px;
      margin-bottom: 20px;
    }
    h2{
      @include font($font-size-18, 600, $black-color);
      line-height: 30px;
      margin-bottom: 20px;
    }
    p{
      @include font($font-size-14, normal, $gray-color);
      line-height: 22px;
      margin-bottom: 8px;
    }
    .button{
      margin: 30px 2px 20px;
      max-width: 370px;
      width: calc(100% - 4px) !important;
    }
    .btn-align-center{
      @include flexM(flex, center, center);
    }
    img{
      max-width: 100%;
    }
    table{
      margin-bottom: 15px;
      tr{
        td{
          border: 1px solid lightgray;
          padding: 0 5px;
        }
      }
    }
  }
  ul{
    margin: 10px 0;
    li{
      @include font($font-size-14, normal, $gray-color);
      margin-bottom: 5px;
      line-height: 24px;
      list-style: disc;
      list-style-position: inside;
    }
  }
}