*,
ul,
dl,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  line-height: normal;
  letter-spacing: normal;
  font-family: $font;
}

.mr-b-70 {
  margin-bottom: 4.375rem;
}

.mr-b-60 {
  margin-bottom: 3.75rem;
}

.mr-b-50 {
  margin-bottom: 3.125rem;
}

.mr-t-50 {
  margin-top: 3.125rem;
}

.mr-b-40 {
  margin-bottom: 2.5rem;
}

.mr-b-36 {
  margin-bottom: 2.25rem;
}

.m-h-130 {
  min-height: 130px !important;
}

.m-h-200 {
  min-height: 200px !important;
}

.mr-b-30 {
  margin-bottom: 1.875rem !important;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }

  &.mob-mr-b-30 {
    margin-bottom: 1.875rem;
  }
}

.mr-b-20 {
  margin-bottom: 1.25rem;
}

.mr-t-20 {
  margin-top: 1.25rem;
}

.mr-b-25 {
  margin-bottom: 1.563rem;
}

.mr-b-15 {
  margin-bottom: 1rem;
}

.mr-b-10 {
  margin-bottom: 10px;
}

.mr-l-15 {
  margin-left: 15px;
}

.mr-b-0 {
  margin-bottom: 0 !important;
}

.mr-r-10 {
  margin-right: 10px;
}

.mr-r-5 {
  margin-right: 5px;
}

.mr-tb-50 {
  margin: 50px 0;
}

.mr-t-50 {
  margin-top: 3.125rem;
}

.mr-t-30 {
  margin-top: 30px;
}

.mr-t-10 {
  margin-top: 10px;
}

.mr-l-0 {
  margin-left: 0 !important;
}

.mr-l-20 {
  margin-left: 20px;
}

.mob-mr-b-0 {
  @media (max-width: 767px) {
    margin-bottom: 0 !important;
  }
}

.mob-mr-b-15 {
  @media (max-width: 767px) {
    margin-bottom: 0.938rem;
  }
}

.mob-mr-b-40 {
  @media (max-width: 767px) {
    margin-bottom: 2.5rem;
  }
}

.mr-tp-25 {
  margin-top: 25px;
}

.w-280 {
  @media (min-width: 767px) {
    width: 280px !important;
  }
}

.w-205 {
  @media (min-width: 479px) {
    width: 205px !important;
  }
}

.w-220 {
  width: 220px !important;
}

.mx-w-100 {
  max-width: 100%;
}

.h-105 {
  height: 105px !important;
}

.h-230 {
  height: 230px !important;
  @media (max-width: 767px) {
    height: 160px !important;
  }
}

.h-80 {
  height: 80px !important;
}

.gray-bg {
  background: $light-gray  !important;
}

.light-gray-bg {
  background: #f4f4f4 !important;
}

.xs-order-1 {
  @media (max-width: 767px) {
    order: 1;
  }
}

.xs-order-2 {
  @media (max-width: 767px) {
    order: 2;
  }
}

.xs-order-3 {
  @media (max-width: 767px) {
    order: 3;
  }
}

@media (max-width: 767px) {
  .xs-gx-2 {
    margin-right: calc(-.5 * 1rem);
    margin-left: calc(-.5 * 1rem);

    >* {
      padding-right: calc(1rem * .5);
      padding-left: calc(1rem * .5);
    }
  }
}

.violet-color {
  color: $primary-light-clr;
}

.pad-0 {
  padding: 0 !important;
}

@media (max-width: 767px) {
  .xs-gx-1 {
    margin-right: calc(-.5 * 0.5rem);
    margin-left: calc(-.5 * 0.5rem);

    >* {
      padding-right: calc(0.5rem * .5);
      padding-left: calc(0.5rem * .5);
    }
  }
}

.text-left {
  text-align: left !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.word-limt {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  font-style: italic;
  font-size: 13px;
  color: #5e6267;
}

.form-floating {
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 77;
  opacity: 0;
  visibility: hidden;
  @include transition(all ease-in-out 0.2s);

  &.active {
    @include transition(all ease-in-out 0.2s);
    opacity: 1;
    visibility: visible;
  }
}

.filter-dropdown {
  @include position(absolute, 100%, 0, auto, 250px);
  background: $white-clr;
  border-radius: $input-radius;
  padding: 10px 0;
  opacity: 0;
  z-index: 999;
  visibility: hidden;
  @include transform(translateY(40px));
  @include transition(all cubic-bezier(0.4, 0, 0.2, 1) 0.2s);

  @media (max-width: 767px) {
    left: auto;
    right: -56px;
  }

  &.right {
    left: auto;
    right: 0;
  }

  &.active {
    visibility: visible;
    opacity: 1;
    @include transform(translateY(0));
  }

  ul {
    max-height: 300px;
    overflow: auto;

    &::-webkit-scrollbar-track {
      border-radius: $input-radius;
      background-color: #f8f8f8;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: $input-radius;
      background-color: #999999;
    }

    li {
      list-style: none;
      @include flexM(flex, center, flex-start);
      margin-left: 0 !important;
      @include font($font-size-p, normal, $black-color);
      cursor: pointer;
      padding: 10px 25px;
      @include transition(all ease-in-out 0.2s);
      width: 100%;

      &:hover {
        @include transition(all ease-in-out 0.2s);
        background-color: #eee;
      }

      a {
        width: 100%;
        text-align: left;
        @include flexM(flex, center, flex-start);
        color: $black-color;

        .flag-space {
          margin-right: 10px;
        }

        img {
          margin-right: 5px;
        }
      }
      img {
        margin-right: 5px;
      }
    }
  }

  .form-check {
    label {
      &::before {
        margin-right: 15px;
      }
    }
  }
}

.red-color,
.text_felony {
  color: $error-clr  !important;
}


.pos-rel {
  position: relative;

  img {
    cursor: pointer;
    position: absolute;
    top: 22px;
    right: 22px;
    width: 20px;
    height: auto;
  }
}

.gray-clr {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.white-bg {
  background: $white-clr  !important;
}

.pad-22-tb {
  padding: 22px 0 !important;

  @media (max-width: 767px) {
    padding: 0 !important;
  }
}

.pad-22-t {
  padding: 22px 0 0 !important;

  @media (max-width: 767px) {
    padding: 0 !important;
  }
}

.rejection-reason,
.succ-reason {
  border: solid 1px rgba(239, 44, 44, 0.4);
  background-color: rgba(239, 44, 44, 0.05);
  border-radius: $input-radius;
  padding: 20px 30px;

  h2 {
    @include font($font-size-md, $heading-font, $error-clr);
    margin-bottom: 15px;
  }

  ul {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;

    li {
      list-style-position: inside;
      @include font($font-size-13, normal, $primary-txt-clr);
      margin-bottom: 15px;
    }
  }

  h6 {
    @include font($font-size-13, normal, $gray-color);
    margin-bottom: 5px
  }

  p {
    @include font($font-size-md, normal, $primary-txt-clr);
    line-height: 2.14;
  }
}

.succ-reason {
  background: rgba(87, 176, 127, 0.1);
  border: solid 1px rgba(87, 176, 127, 0.1);

  h2 {
    color: $succ-clr;
  }
}

.check-in-block {
  background: rgba(94, 98, 103, 0.05);
  padding: 30px;
  border-radius: $input-radius;

  @include size(tablet-up) {
    padding: 20px;
  }

  .txt-block {
    @include flexM(inline-flex, center, flex-start);

    .img {
      min-width: 40px;
      height: 40px;
      @include flexM(inline-flex, center, center);
      background: rgba(33, 35, 38, 0.1);
      border-radius: $input-radius;
      margin-right: 20px;
    }

    .text {
      width: calc(100% - 60px);

      h3 {
        @include font($font-size-p, $med-font, $primary-txt-clr);
        margin-bottom: 5px;
      }

      p {
        @include font($font-size-13, normal, $gray-color);
      }
    }
  }

  .flex-end {
    @include flexM(inline-flex, center, flex-end);
  }

  &.white-bg {
    background-color: $white-clr;
  }
}

.mob-align-center {
  @include size(tablet-up) {
    justify-content: center !important;
  }
}

.download-evict {
  background: rgba(94, 98, 103, 0.05);
  padding: 13px 20px;
  border-radius: $input-radius;

  p {
    img {
      max-width: 30px;
    }

    @include font($font-size-13, normal, $gray-color);
  }

  .align-right {
    @include flexM(flex, center, flex-end);
    height: 100%;
    a {
      @include font($font-size-12, $semi-font, $primary-light-clr);
      text-transform: $capital;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.bdr-btm-n {
  border-bottom: none;

  img {
    max-width: 25px;
  }
}

.content-block {
  padding: 30px 0;
  border-bottom: 1px solid rgba(33, 35, 38, 0.1);
  &.brd-btm-n {
    border-bottom: none;
  }
}

.upload-img-list {
  @include flexM(flex, center, flex-start);
  margin-top: 10px;
  flex-flow: row wrap;
  &.admin-eviction{
    margin-top: 0;
    .img-item{
      margin: 10px 20px 10px 0;
    }
  }
  &.eviction-images {
    .img-item {
      margin-right: 0;
    }
  }

  .img-item {
    display: flex;
    margin-right: 20px;
    position: relative;
    border-radius: $input-radius;
    overflow: hidden;

    @media (max-width: 767px) {
      margin: 3px;
    }

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: $input-radius;
    }

    &::after {
      content: "";
      @include position(absolute, 0, 0, 100%, 100%);
      background: #000;
      opacity: 0.5;
    }
  }

  .delete-ic {
    position: absolute;
    top: 6px;
    right: 10px;
    z-index: 1;

    img {
      cursor: pointer;
      height: auto;
      width: auto;
    }
  }
}

.notification {
  .table-col {
    .prop-img {
      background: rgba(33, 35, 38, 0.1);
      @include flexM(flex, center, center);
      border-radius: $input-radius;
      min-width: 40px;
      height: 40px;
    }

    .prop-detail {
      h4 {
        font-size: $font-size-p;
      }
    }

    .arrow-img {
      @include flexM(flex, center, flex-end);
      width: 100%;

      img {
        cursor: pointer;
      }

      &.show-from-top {
        @include size(tablet-up) {
          position: absolute;
          top: 27px;
        }
      }
    }
  }
}

.no-content {
  @include flexM(flex, center, center);
  flex-flow: column;
  height: 100%;

  @include size(tablet-up) {
    height: auto;
  }

  img {
    mix-blend-mode: luminosity;
    margin-bottom: 50px;
  }

  h2 {
    @include font($font-size-lg, $heading-font, $primary-txt-clr);
    margin-bottom: 10px;
  }

  p {
    @include font($font-size-p, normal, $gray-color);
    line-height: 1.73;
    max-width: 350px;
    text-align: center;
  }

  .btn {
    margin-top: 40px;
    min-width: 220px;
    font-size: $font-size-12;
    padding: 11px;
  }
}

.listing-featured {
  @include flexM(flex, center, center);
  flex-flow: column;
  max-width: 672px;
  margin: auto;

  h2 {
    @include font($font-size-lg, $heading-font, $primary-txt-clr);
    margin: 50px 0 20px 0;

    @include size(tablet-up) {
      text-align: center;
    }
  }

  p {
    @include font($font-size-p, normal, $gray-color);
    line-height: 1.73;
    max-width: 550px;
    text-align: center;
  }

  .btn {
    margin-top: 40px;
    min-width: 220px;
    padding: 11px;
  }
}

.d-flex {
  display: flex;
}

.error-message {
  @include font($font-size-md  !important, normal, $error-clr  !important);
  // margin-top: 10px;
  line-height: 22px;

  @media (max-width: 767px) {
    margin: -10px 0 20px;
    font-size: $font-size-13;
  }

  &.user\.toc_accepted_error,
  &.toc_accepted_error {
    position: absolute;
    top: 40px;
    left: 0;

    @media (max-width: 767px) {
      margin: 10px 0 0 0;
    }
  }

  &.upload-img-error {
    @media (max-width: 767px) {
      margin: 10px 0 0 0;
    }
  }

  &:empty {
    display: none;
  }
}

.error-style-mob {
  .error-message {
    @media (max-width: 767px) {
      margin: 10px 0 0 0;
    }
  }
}

.error-custom {
  p {
    margin: 0 0 12px;
  }
}

// Loading style
.loading-wrp {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0,0.95);
  .loading {
    width: 3rem;
    height: 3rem;

    &.text-secondary {
      color: $white-clr  !important;
    }
  }
}

.hidden-in-mobile {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.show-in-mobile {
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }
}

.main-loading {
  width: 5.25rem;
  height: 5.25rem;
  border-right-color: #cfd0d2;
  color: $primary-light-clr  !important;

  &.wid-50 {
    width: 3.125rem;
    height: 3.125rem;
  }
}

// Toast Message UI
.toast {
  &.toast-customize {
    position: fixed;
    top: calc(50% - 20px);
    right: calc(50% - 180px);
    background-color: $white-clr  !important;
    border-radius: $input-radius;
    @include font($font-size-15, normal, $primary-txt-clr);
    z-index: 999999;
    width: 360px;

    @media (max-width: 420px) {
      right: 0;
      left: 0;
      margin: auto;
      width: 310px;
    }

    .toast-header {
      position: absolute;
      top: 24px;
      right: 15px;
      background: transparent;
      border: none;

      .btn-close {
        margin: 0;
        outline: none;
        width: 10px;
        height: 10px;
        padding: 1px;

        &:focus {
          outline: none;
          box-shadow: inherit;
        }
      }
    }

    .toast-body {
      padding: 1.375rem 2.7rem 1.375rem 1.25rem;
      @include flexM(flex, center, flex-start);
      line-height: 20px;

      @media (max-width: 420px) {
        font-size: $font-size-14;
        padding-right: 3rem;
        line-height: 17px;
      }

      .icon {
        min-width: 35px;
        height: 35px;
        border-radius: 50%;
        @include flexM(inline-flex, center, center);
        margin-right: 10px;
        background: $succ-clr;

        &[bg="warning"] {
          background: $error-clr;
        }
      }
    }
  }
}

.carousel-inner {
  border-radius: $input-radius;

  .carousel-item {
    img {
      max-height: 650px;
      object-fit: fill;

      @media (max-width: 767px) {
        aspect-ratio: 3 / 2;
      }
    }
  }
}

.text-center-mob {
  @media (max-width: 767px) {
    text-align: center;
  }
}

// Loading Spinner
@keyframes blink {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}

.spinner-loader {
  @include flexM(flex, center, center);

  .content-wrp {
    @include flexM(inline-flex, center, center);
    box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
    background-color: $white-clr;
    padding: 12px 20px;
    border-radius: $border-r-20;

    span {
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $primary-light-clr;
      display: inline-flex;
      margin-right: 5px;

      &:nth-child(2) {
        animation-delay: .2s;
      }

      &:nth-child(3) {
        animation-delay: .4s;
      }
    }

    p {
      display: inline-flex;
      @include font($font-size-13, normal, $gray-color);
      margin-left: 10px;
    }
  }
}

.infinite-scroll-component {
  overflow: inherit !important;
}

.upload-doc {
  padding: 1.875rem;
  border-radius: $input-radius;
  background-color: $light-gray;
  border: 1px dashed #c8c8c8;
  position: relative;
  @include flexM(flex, center, flex-start);

  img {
    object-fit: cover;
  }

  .form-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
  }

  .txt {
    margin-left: 15px;

    h5 {
      @include font($font-size-15, $med-font, $primary-txt-clr);
      margin-bottom: 5px;
    }

    p {
      @include font($font-size-14, normal, $gray-color);
    }
  }
}

.uploaded-doc {
  position: relative;

  h4 {
    @include font($font-size-14, normal, $primary-txt-clr);
  }

  img {
    cursor: pointer;
    position: absolute;
    top: -4px;
    right: 0;
  }
}

.tab-txt, .content-block {
  p {
    @include font($font-size-14, normal, $gray-color);
    line-height: 2;
    margin-bottom: 15px;
  }

  h1 {
    @include font($font-size-xl, normal, $primary-txt-clr);
    margin-bottom: 15px;
  }

  h2 {
    @include font($font-size-lg, normal, $primary-txt-clr);
    margin-bottom: 15px;
  }

  h3 {
    @include font($font-size-20, normal, $primary-txt-clr);
    margin-bottom: 15px;
  }

  ul,
  ol {
    padding-left: 15px;

    li {
      @include font($font-size-14, normal, $gray-color);
      line-height: 2;
    }
  }
  a{
    text-decoration: underline;
    color: #0d6efd;
    &:hover{
      text-decoration: none;
    }
  }
  br{
    display: none;
  }
}

.button-2 {
  &.edit-page {
    @include flexM(flex, center, flex-end);

    .btn {
      min-width: 250px;
      min-height: 50px;
      margin-left: 20px;
    }
  }
}

.editor-content {
  .quill {
    background-color: $white-clr;
    border-radius: $input-radius;

    .ql-container.ql-snow {
      border: none;

      .ql-editor {
        padding: 50px;
      }
    }

    .ql-toolbar.ql-snow {
      border: none;
      border-bottom: 1px solid #ededed;
      padding: 20px;
    }
  }

  p {
    @include font($font-size-14, normal, $gray-color);
    line-height: 2;
    margin-bottom: 20px;
  }
}

.banner-sec {
  .main-header {
    &.is-sticky {
      &::after {
        content: "";
        @include position(absolute, 0, 0, 100%, 100%);
        z-index: -1;
        background-color: rgba(0, 0, 0, .92);
        backdrop-filter: saturate(100%) blur(20px);
      }
    }
  }
}

.v-flex-start {
  .row {
    .col {
      align-items: flex-start;
      display: flex;
      flex-flow: row wrap;
    }
  }
}

.otp-country-flag {
  width: 75px;
  margin-right: 10px;
}

.form-container {
  &.review-detail-pg {

    .switch-block,
    .lease-signor-block {
      &.white-bg {
        background-color: $light-gray  !important;
      }
      
    }
  }
}

.pin {
  .pin-text {
    @include font($font-size-15, bold, $primary-txt-clr);
    padding: 7px 25px;
    border-radius: 15px;
    background-color: $white-clr;
    display: inline-block;
  }
}

hr:not([size]) {
  height: .5px;
  opacity: 0.07;
}

.react-select {
  position: relative;
  z-index: 11;

  &.disabled {
    pointer-events: none;
    opacity: .6;
  }
}

.dashboard-page {
  .dash-body {
    .dash-body {
      padding: 0;

      .heading {
        display: none;
      }
    }
  }
  .form-container{
    &.min-height{
      margin: 0;
      .owner-body{
        min-height: calc(100vh - 71px);
      }
    }
  }
  .owner-footer{
    display: none;
  }
}

.inner-word-break {
  p {
    word-break: break-word;
  }
}

.view-reviews {
  @include flexM(flex, center, flex-end);

  @media (max-width: 767px) {
    justify-content: flex-start;
    margin-bottom: 25px;
  }

  .text {
    margin-left: 10px;

    span {
      @include font($font-size-12, bold, $primary-light-clr);
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.xs-gx-1 {
  @media (max-width: 1250px) and (min-width: 1100px) {
    .col-12 {
      width: 62.66666667%;
    }

    .col-9 {
      width: 20.66666667%;
    }
  }
}

.promo-check-option {
  >div {
    display: flex;
  }

  .custom-checkbox {
    width: 50%;
    display: flex;
  }
}

.booking-cancel-modal {
  &.w-400{
    .modal-dialog{
      max-width: 400px;
    }
  }
  >p {
    @include font($font-size-14, normal, $gray-color);
    line-height: 24px;
    margin-bottom: 10px;
  }

  .price-breakdown {
    .item {
      border-bottom: 1px solid rgba(94, 98, 103, 0.1);
      padding: 15px 0;

      &:last-of-type {
        border-bottom: none;

        .flex-end {
          p {
            font-weight: $heading-font;
            color: $error-clr;
          }
        }
      }

      .flex-end {
        justify-content: flex-end;
        display: flex;
      }

      p {
        @include font($font-size-14, normal, $gray-color);
      }
    }
  }

  &.purple-total {
    .price-breakdown {
      .item {
        &:last-of-type {
          .flex-end {
            p {
              color: $primary-light-clr;
            }
          }
        }
      }
    }
  }
}

.info-for-form {
  padding-left: 25px;
  position: relative;
  @include font($font-size-14, normal, $gray-color);
  font-style: italic;
  line-height: 22px;
  margin-bottom: 25px;

  img {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.profile-lease-signors {
  .hide-4-profile {
    display: none;
  }

  .add-pad {
    .row {
      .col-md-2 {

        &:nth-child(2),
        &:nth-child(4) {
          width: 17%;
        }
        &:nth-child(5),
        &:nth-child(6){
          width: 14%;
        }
      }
    }
  }
}

.disabled-checkin-checkout {
  opacity: 0.6;
  pointer-events: none;
}

.check-In-pop {
  .image-overflow-mange {
    max-height: 240px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .thumb-img {
    margin-bottom: 20px;

    img {
      height: 100px;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}

.email-notification-banner {
  background: #8754a2;
  border-radius: $input-radius;
  padding: 15px 25px;
  border: 1px dashed $white-clr;
  @include font($font-size-15, normal, $white-clr);
  margin: 30px 30px -10px 0;

  @include size(tablet-up) {
    margin: 10px 10px 20px;
    line-height: 22px;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: $white-clr;
      text-decoration: none;
    }
  }
}

.admin-add-comment {
  margin-top: 30px;

  h6 {
    @include font($font-size-13, normal, $gray-color);
    margin-bottom: 10px
  }

  p {
    @include font($font-size-15, normal, $black-color);
    line-height: 26px;
  }
}

.tooltip {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    font-size: $font-size-13;
    line-height: 20px;
  }
}

.captcha-wrp {
  margin-top: 30px;

  div {
    width: 100% !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.word-break-all {
  word-break: break-word;
  line-height: 20px;
}

.p-t-b-5 {
  padding: 7px 0;

  p {
    @include size(phone) {
      font-size: 14px !important;
    }
  }
}

.column-pad-mang {
  .border-btm {
    @include size(tablet-up) {
      padding: 10px 0 !important;
    }
  }
}

.mob-mr-t-20 {
  @include size(tablet-up) {
    margin-top: 20px;
  }
}

.mob-mr-t-10 {
  @include size(tablet-up) {
    margin-top: 10px;
  }
}

.lease-doc-head-mob {
  font-size: 14px;
  color: #5e6267;
  margin-bottom: 4px;
}

.table-body.leases-doc-table {
  .table-col.separator-line {
    &::after {
      bottom: 35px;
    }
  }
}

.featured-listing {
  @include size(tablet-up) {
    padding-top: 42px !important;
  }

  .status-btn {
    &.featured {
      @include size(tablet-up) {
        position: absolute;
        top: 15px;
        left: 20px;
        padding: 2px 5px;
        min-width: auto;
        font-size: 10px;
      }
    }
  }
}

.featured-sections {
  .error-message {
    @include size(tablet-up) {
      margin-top: 10px;
    }
  }

  .custom-checkbox {
    label {
      &::after {
        @media (max-width: 420px) {
          top: auto !important;
        }
      }
    }
  }
}

.in-mob-flex-flow {
  @media (max-width: 374px) {
    flex-flow: row wrap;
    justify-content: center !important;
  }

  .room-detail {
    @media (max-width: 374px) {
      margin-bottom: 12px;
    }
  }

  .status-btn {
    @media (max-width: 374px) {
      margin: 0 !important;
    }
  }
}

.auto-w-box {
  &.v-flex-start {
    .error-message {
      width: 100%;

      @include size(tablet-up) {
        margin-top: 10px;
      }
    }
  }
}

.dat-time {
  opacity: 0.7;
  margin-right: 6px;
}

.mob-align-left {
  @include size(tablet-up) {
    justify-content: flex-start !important;
  }
}

.pad-tp-10 {
  padding: 10px !important;
}

.bg-color-white {
  background-color: #ffffff !important;
}

.user-mange-filter {
  margin-top: 40px;

  .action-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 65px;

    .button {
      width: 48%;
    }
  }

  .align-right {
    .form-floating {
      min-width: 190px;
    }
  }
  .form-floating{
    label{
      @media (min-width: 1200px) {
        font-size: 14px;
      }
    }
  }
}
.green-color, .text_clear {
  color: $succ-clr !important;
}

.text_misdemeanor {
  color: orange !important;
}

.admin-list-images {
  position: relative;

  .button-black {
    position: absolute;
    left: 20px;
    bottom: 20px;
    @include font($font-size-13, $heading-font, $white-clr);
    text-transform: uppercase;
    padding: 15px 20px;
    background-color: #212326;
    border: none;
    border-radius: $input-radius;
    z-index: 99;
  }
}

.button-2-rating {
  display: flex;
  margin-top: 20px;
}

.rang-slider {
  margin-bottom: 30px;

  .form-label {
    justify-content: space-between;
    display: flex;
    @include font($font-size-15, 600, $black-color);

    span {
      @include font($font-size-12, normal, $black-color);
    }
  }

  input[type="range"] {
    &::-webkit-slider-runnable-track {
      background-color: #eeefef;
      border-radius: 0.5rem;
      height: 15px;
    }

    &::-moz-range-track {
      background-color: #eeefef;
      border-radius: 0.5rem;
      height: 15px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      margin-top: -6px;
      box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.3);
      background-color: #fff;
      height: 25px;
      width: 25px;
    }

    &::-moz-range-thumb {
      border: none;
      border-radius: 50%;
      background-color: #5cd5eb;
      box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.3);
      background-color: #fff;
      height: 25px;
      width: 25px;
    }

    &:focus {
      &::-webkit-slider-thumb {
        border: none;
        outline: 3px solid transparent;
        outline-offset: 0;
      }

      &::-moz-range-thumb {
        border: none;
        outline: 3px solid transparent;
        outline-offset: 0;
      }
    }
  }

  input[value="1"] {
    &::-webkit-slider-runnable-track {
      background: linear-gradient(90deg, rgba(181, 62, 240, 1) 20%, rgba(238, 239, 239, 1) 20%);
    }

    &::-moz-range-track {
      background: linear-gradient(90deg, rgba(181, 62, 240, 1) 20%, rgba(238, 239, 239, 1) 20%);
    }
  }

  input[value="2"] {
    &::-webkit-slider-runnable-track {
      background: linear-gradient(90deg, rgba(181, 62, 240, 1) 40%, rgba(238, 239, 239, 1) 40%);
    }

    &::-moz-range-track {
      background: linear-gradient(90deg, rgba(181, 62, 240, 1) 40%, rgba(238, 239, 239, 1) 40%);
    }
  }

  input[value="3"] {
    &::-webkit-slider-runnable-track {
      background: linear-gradient(90deg, rgba(181, 62, 240, 1) 60%, rgba(238, 239, 239, 1) 60%);
    }

    &::-moz-range-track {
      background: linear-gradient(90deg, rgba(181, 62, 240, 1) 60%, rgba(238, 239, 239, 1) 60%);
    }
  }

  input[value="4"] {
    &::-webkit-slider-runnable-track {
      background: linear-gradient(90deg, rgba(181, 62, 240, 1) 80%, rgba(238, 239, 239, 1) 80%);
    }

    &::-moz-range-track {
      background: linear-gradient(90deg, rgba(181, 62, 240, 1) 80%, rgba(238, 239, 239, 1) 80%);
    }
  }

  input[value="5"] {
    &::-webkit-slider-runnable-track {
      background: linear-gradient(90deg, rgba(181, 62, 240, 1) 100%, rgba(238, 239, 239, 1) 100%);
    }

    &::-moz-range-track {
      background: linear-gradient(90deg, rgba(181, 62, 240, 1) 100%, rgba(238, 239, 239, 1) 100%);
    }
  }
}

.react-stars {
  @include flexM(flex, center, center);
  width: 100%;
  height: 32x;
}

.green-color {
  p {
    color: $succ-clr  !important;
  }
}

.red-color {
  p {
    color: $error-clr  !important;
  }
}

.exprot-cal {
  @include flexM(flex, center, center);
  flex-direction: column;
  background-color: $light-gray;
  border-radius: $input-radius;
  padding: 40px;
  margin-bottom: 30px;

  p {
    @include font($font-size-15, normal, $gray-color);
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  span {
    @include font($font-size-16, $bold-font, $black-color);
    word-break: break-all;
  }
}

.apply-coupen-form {
  background-color: #f3f3f3;
  padding: 30px;
  border-radius: $input-radius;
  margin-bottom: 30px;

  h6 {
    margin-bottom: 25px;
  }

  .form-floating {
    label {
      display: none;
    }

    .form-control {
      background: #e3e4e4;
      height: auto;
      padding: 15px;

      &::-webkit-input-placeholder {
        color: $gray-color;
      }

      &:-ms-input-placeholder {
        color: $gray-color;
      }

      &::placeholder {
        color: $gray-color;
      }
    }
  }
}

.apply-coupen-txt {
  box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
  background: white;
  border-radius: $input-radius;
  margin-bottom: 30px;
  padding: 25px 18px;

  h6 {
    @include font($font-size-13, normal, $gray-color);
    margin-bottom: 5px;

    @include size(tablet-up) {
      margin-bottom: 10px;
    }
  }

  p {
    font-size: $font-size-14;

    @include size(tablet-up) {
      margin-bottom: 15px;
      line-height: 22px
    }
  }

  .flex-end {
    @include flexM(flex, center, flex-end);

    .mr-l-15 {
      @include size(tablet-up) {
        margin-left: 0;
      }
    }
  }

  .mr-l-10 {
    margin-left: 10px;
  }
}

.bank-status {
  h4 {
    @include font($font-size-15, $med-font, $primary-txt-clr);
    padding: 25px 30px 12px;
  }

  p {
    @include font($font-size-14, normal, $succ-clr  !important);
    padding: 20px 30px;
  }

  ul {
    padding: 0 30px 20px;

    li {
      @include font($font-size-14, normal, $gray-color);
      list-style-position: inside;
      line-height: 26px;
    }
  }
}

.mr-t-0 {
  @media (min-width: 767px) {
    margin-top: 0;
  }
}

.upload-your-id {
  position: relative;
  margin-top: 30px;

  h3 {
    @include font($font-size-20, bold, $primary-txt-clr);
    margin-bottom: 20px;
  }
}

.disclaimer-text {
  font-style: italic;
  background: rgba(0, 0, 0, 0.05);
  font-size: 12px;
  padding: 10px 20px;
  display: block;
  border-radius: 5px;
  margin-bottom: 30px;
}

.rating-disclaimer p {
  font-style: italic;
  background: rgba(0, 0, 0, 0.05);
  font-size: 10px;
  padding: 5px 10px;
  display: block;
  border-radius: 5px;
  margin-bottom: 15px;
  line-height: 15px;
}

.user-info {
  .img-item {
    margin-right: 10px;

    img {
      border-radius: 6px;
    }
  }
}

.imported-calendars {
  margin-top: 34px;

  h5 {
    @include font($font-size-17, $semi-font, $primary-txt-clr);
    margin-bottom: 15px;
  }

  p {
    line-height: 26px;
    word-break: break-word;

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .flex-end {
    img {
      cursor: pointer;
    }

    &.mob-center {
      @media (max-width: 767px) {
        justify-content: center !important;
      }
    }
  }
}

.btn-mob-width-100 {
  .status-btn {
    @media (max-width: 767px) {
      width: 100%;
      margin-top: 20px;
    }
  }
}

.eviction-pricing-detial-heading {
  h6 {
    @include font($font-size-14, $bold-font, $black-color);
    margin-bottom: 10px;
  }
}

.eviction-pricing-detial {
  background-color: #f7f7f7;
  border-radius: $input-radius;
  border-radius: 0.625rem;
  padding: 15px;
  margin-bottom: 30px;

  h6 {
    @include font($font-size-13, normal, $primary-light-clr);
    margin-bottom: 10px;
  }

  .price-breakdown {
    margin-bottom: 20px;

    &.mr-b-0 {
      margin-bottom: 0;
    }

    .item {
      margin: 10px 0;

      p {
        @include font($font-size-14, normal, $black-color);
      }

      b {
        @include font($font-size-14, $bold-font, $black-color);
      }
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

.align-item-right {
  @include flexM(flex, center, flex-end);
}

.mr-b-20-imp {
  margin-bottom: 20px !important;
}

.termination-prop-detail {
  margin-top: 22px;

  @media (max-width: 767px) {
    margin-top: 0;
  }

  h4 {
    @include font($font-size-13, normal, $black-color);

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  p {
    @include font($font-size-14, $semi-font, $black-color)
  }
}

.dark-gray-bg {
  background-color: #e6e6e6;
  border-radius: 5px;
}

.single-pg-header {
  .is-sticky {
    background-color: $white-clr;
  }

  .home-logo {
    display: none;
  }
}

.cancel-booking-page {
  @include flexM(flex, center, center);
  flex-flow: column;
  min-height: calc(100vh - 100px);
  background-color: $light-gray;

  .cancel-icon {
    img {
      max-width: 115px;
      margin: 30px 0 20px;

      @media (max-width: 767px) {
        width: 85px;
      }
    }
  }

  .icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    @include flexM(flex, center, center);
    background-color: white;
    box-shadow: 0px 3px 18px 6px #00000017;
    margin: 40px 0;

    @media (max-width: 767px) {
      width: 80px;
      height: 80px;
    }

    img {
      min-width: 40px;

      @media (max-width: 767px) {
        min-width: 30px;
      }
    }
  }

  h3 {
    @include font($font-size-lg, $bold-font, $black-color);
    text-align: center;
    line-height: 36px;

    @media (max-width: 767px) {
      font-size: $font-size-17;
      padding: 0 15px;
    }
  }

  p {
    @include font($font-size-15, normal, $gray-color);
    text-align: center;
    line-height: 24px;

    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }

  .spinner-loader {
    .content-wrp {
      box-shadow: inherit;

      span {
        width: 9px;
        height: 9px;
        margin-right: 8px;

        @media (max-width: 767px) {
          width: 8px;
          height: 8px;
          margin-right: 5px;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

.verify-background-check {
  padding: 30px 0;
  margin-bottom: -53px;
  position: relative;
  z-index: 55;
  background: $white-clr;
  text-align: center;
  border-top: 1px solid #ebebeb;
  position: sticky;
  top: 98px;

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  .counter {
    padding: 10px 20px;
    border-radius: 10px;
    border: solid 1px rgba(239, 44, 44, 0.5);
    background-color: rgba(239, 44, 44, 0.05);

    p {
      font-size: $font-size-16;
      line-height: 24px;
      text-align: left;
      color: #ef2c2c;
      font-weight: 600;

      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }
}

.single-pg-button-wrp {
  @include flexM(flex, center, center);
  padding: 30px 0;

  .button {
    min-width: 200px;
    padding: 12px;

    @media (max-width: 767px) {
      font-size: $font-size-13;
      padding: 11px;
    }
  }
  .confirm-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 767px) {
        flex-flow: column;
      }
      .custom-checkbox{
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }
  }
}

.p-full-wid {
  p {
    max-width: inherit !important;
  }
}
.pac-container {
  z-index: 2222;
}
.view-cancellation-policy {
  p {
    font-size: 15px;
    font-weight: normal;
    color: #5e6267;
    line-height: 28px;
  }

  a {
    color: #b53ef0;
    text-decoration: underline;
  }
}

.input-field-sms {
  p {
    margin-left: 15px;
  }
}

.white-background {
  .form-control {
    background-color: $white-clr  !important;
  }
}

.input-suggetion {
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
  span{
    @include font(12px, normal, $gray-color);
    font-style: italic;
    margin-top: 8px;
    line-height: 16px;
    display: block;
    &.mr-l-15{
      margin-left: 15px;
    }
  }
}

.user-management-filter {
  .input-field-sms{
    width: 80%;
  }
}
.video-thumb {
  background-color: #999;
  border-radius: 10px;
}
.pos-rel-0{
  position: relative;
  z-index: 0;
}
.loading-message{
  @include font(14px, normal, $gray-color);
  margin-bottom: 20px;
  display: block;
}
.hanged-error-sms{
  position: relative;
  .error-message{
    position: absolute;
    left: 0;
    right: 0;
    font-size: 12px !important;
    background: white;
    line-height: 18px;
    padding: 8px 12px;
    width: 235px;
    box-shadow: 2px 5px 40px 0 rgba(0, 0, 0, 0.1);
    margin: 10px auto 0;
    border-radius: 2px;
    @media (max-width: 1400px) {
      width: 200px
    }
    @media (max-width: 767px) {
      width: 100%;
      position: relative;
    }
  }
}

.info-text p {
  @include font(12px, normal, $gray-color);
  margin-top: 8px;
  font-style: italic;
}
.info-text-des{
  color: #111 !important;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
}
.mob-pb-15{
  padding-bottom: 15px;
}
.cursor-pointer{
  cursor: pointer;
}
.min-wid-inherit{
  min-width: inherit !important;
}
.font-size-13{
  font-size: $font-size-13;
  line-height: 18px;
}
.create-list-banner{
  background-color: #69298b;
  padding: 30px 30px 0;
  @media (max-width: 767px) {
    padding: 9px 10px 0;
  }
  .email-notification-banner{
    margin: 0;
  }
  &:empty{
    display: none;
  }
}
.goto-contact-pg{
  margin: 30px 0 0;
  p{
    @include font($font-size-15, normal, $black-color);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    @media (max-width: 560px) {
      text-align: center;
      font-size: $font-size-14;
      line-height: 22px;
    }
    a{
      color: $primary-light-clr;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
.renter-pg{
  .renter-list{
    padding-left: 0 !important;
    li{
      list-style: none;
      position: relative;
      padding-left: 40px;
      margin-bottom: 12px;
      font-size: $font-size-15;
      &::after{
        position: absolute;
        top: 1px;
        left: 0;
        content: "";
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-image: url(../../img/check-ic.svg);
        background-size: 16px;
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 2px 4px 5px 0 rgba(0, 0, 0, 0.07);
      }
    }
  }
}
.pad-tb-50 {
  padding: 60px 0;
}
.pos-rel{
  #password, #old_password, #confirm_password {
    padding-right: 50px;
  }
}
.choose-countary-code{
  .css-1s2u09g-control, .css-13cymwt-control {
    border: none;
    background: #f8f8f8;
    display: flex;
    min-height: 65px;
    border-radius: 8px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  .css-1pahdxg-control, .css-t3ipsp-control {
    border: none;
    background: #f8f8f8;
    display: flex;
    min-height: 65px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px #69298b;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}
.form-select, .css-1pahdxg-control, .css-1s2u09g-control, .css-13cymwt-control, .css-t3ipsp-control{
  cursor: pointer !important;
}
#background-iframe{
  @media (max-width: 767px) {
    height: 2520px;
  }
}

.scroll-style{
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: #69298b;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
    outline:none;
    border-radius: 8px;
  }
}
.re-verify {
  border: 1px solid #ff0000;
  background: transparent;
  cursor: pointer;
  color: #ff0000;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  position: relative;
  padding-left: 36px;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
  &:after{
    content: "";
    background: #ff0000 url('../../img/tick-icon.svg') center no-repeat;
    color: transparent;
    background-size: 17px;
    background-position: center;
    position: absolute;
    top: 6px;
    left: 12px;
    width: 17px;
    height: 17px;
    border-radius: 15px;
  }
  &:hover, &:focus{
    border-color: #ff0000;
    background-color: transparent;
    color:#ff0000;
  }
}
.badge-count-inline {
  background-color: #e3091e;
  font-size: 11px;
  padding: 3px 6px;
  border-radius: 10px;
  color: white;
  font-weight: 400;
  display: inline-flex;
  &.mr-l-5{
    margin-left: 5px;
  }
}
.payout-bank-list{
  + .bank-table {
    + .mob-bank-table{
      .delete-btn {
        display: none;
      }
    }
    .row{
      .col{
        &:last-child{
          .table-item{
            display: none;
          }
        }
      }
    }
  }
}
.create-new-listing-pg{
  .main-wrapper{
    .right-sidebar{
      background-color: #69298b;
    }
  }
}
.create-list-bank-info{
  .row{
    .col-md-1{
      display: none;
    }
  }
}
.comment-field {
    textarea {
      border: 1px solid #ced4da !important;
      border-radius: 10px;
      padding: 10px !important;
      font-size: 14px;
      &:hover, &:focus{
          box-shadow: 0 0 0 1px #69298b;
          -webkit-box-shadow: 0 0 0 1px #69298b;
          outline: none;
      }
    }
}
.next-step-warn {
  font-size: 16px;
  color: #222;
  margin-bottom: 40px;
  line-height: 24px;
}


