$primary-clr: #69298b;
$primary-light-clr: #b53ef0;
$white-clr: #ffffff;
$light-gray: #f8f8f8;
$gray-color: #5e6267;
$gray-color-53: #535353;
$primary-txt-clr: #212326;
$black-color: #000000;
$succ-clr: #57b07f;
$error-clr: #ef2c2c;
$blue-clr: #6c98ef;
$color-4a: #4a4a4a;
$input-radius: 0.625rem;

$font-size-50: 50px;
$font-size-40: 40px;
$font-size-35: 35px;
$font-size-xl: 30px;
$font-size-lg: 25px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-17: 17px;
$font-size-19: 19px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-p: 15px;
$font-size-16: 16px;
$font-size-h3: 17px;
$font-size-md: 14px;
$font-size-sm: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font: 'Archivo', sans-serif;
$font-caveat: 'Caveat', cursive;
$lt-font: 300;
$reg-font: 400;
$med-font: 500;
$semi-font: 600;
$bold-font: 700;
$bold-font-8: 800;
$e-bold-font: 900;
$heading-font: bold;
$capital: uppercase;
$border-r-20: 20px;
$border-r-4: 4px;

$published-bg-clr: #f7ebfd;
$published-clr: #b53ef0;
$rejected-bg-clr: #fde9e9;
$rejected-clr: #ef2c2c;
$featured-bg-clr: #f0f4fd;
$featured-clr: #6c98ef;

/* GRADIENT COLOR */
$gradient-color: $primary-light-clr, $primary-clr;
$gradient-color2: rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85);
$total-booking-color:#3e85f0, #8a24bd;
$total-search-color: #699c70, #2d63ab;
$succ-gradient-color: #459267, #57b07f;