.page-banner{
  .banner-image{
    min-height: 300px;
    background-size: cover;
    margin-bottom: 3.125rem;
    border-radius: $input-radius;
    @include flexM(flex, center, center);
    background-position: center;
    @media (max-width: 767px) {
      min-height: 150px;
      margin-bottom: 40px;
    }
    h1{
      @include font($font-size-50, $med-font, $light-gray);
      line-height: 1.3;
      text-align: center;
      @media (max-width: 767px) {
        font-size: $font-size-22;
      }
    }
  }
}
.content-block{
  @media (max-width: 767px) {
    padding-top: 0;
  }
  .content{
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    strong{
      @include font($font-size-15, $semi-font, $primary-txt-clr);
      display: flex;
      line-height: 2;
      margin-bottom: 10px;
    }
    p{
      @include font($font-size-15, normal, $gray-color);
      line-height: 2;
    }
  }
}
.accordion-sec{
  margin-bottom: 100px;
  .accordion-item{
    margin-bottom: 20px;
    border: none;
    box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
    border-radius: $input-radius;
    overflow: hidden;
    padding: 20px 40px;
    .accordion-header{
      .accordion-button{
        box-shadow: inherit;
        @include font($font-size-15, $semi-font, $primary-txt-clr);
        padding: 0;
        background: $white-clr;
        line-height: 2;
        &::after{
          background-image: url('../../img/drop-arrow.svg');
          background-size: auto;
        }
      }
    }
    .accordion-collapse{
      .accordion-body{
        padding: 0;
        @include font($font-size-15, normal, $gray-color);
        line-height: 2;
        position: relative;
        .fqa-action{
          position: absolute;
          top: -30px;
          right: 30px;
          z-index: 11;
          span{
            display: inline-flex;
            cursor: pointer;
            margin-left: 10px;
          }
        }
      }
    }
  }
}