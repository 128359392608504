.button-wrp{
  .col{
    display: flex;
    justify-content: flex-end;
    @media (max-width: 767px) {
      width: 100%;
      flex-flow: column;
    }
    .btn{
      color: $white-clr;
      text-transform: $capital;
      font-family: $font;
      font-size: $font-size-md;
      font-weight: $med-font;
      border-radius: $input-radius;
      width: 250px;
      padding: 0.906rem;
      border: none;
      &.btn-secondary{
        background: $gray-color;
        margin-right: 20px;
        @media (max-width: 767px) {
          width: 100%;
          margin: 0 0 15px 0;
        }
      }
      &.w-290{
        min-width: 290px;
        @media (max-width: 767px) {
          min-width: 100%;
        }
      }
      &.btn-primary{
        background: $primary-clr;
        @include gradient(to right, $gradient-color);
      }
      &.sm-btn{
        width: 210px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  &.edit-profile{
    .button {
      font-size: $font-size-12;
      padding: 11px;
      min-width: 150px;
      width: auto;
      &:nth-child(2){
        min-width: 225px;
      }
    }
  }
}
.price-btn{
  background: #4d4f51;
  @include font(12px, normal, $white-clr);
  border: none;
  border-radius: $input-radius;
  padding: 7px 10px;
  cursor: auto ;
  @media (max-width: 767px) {
    width: 100%;
    padding: 7px;
  }
  b{
      @include font(11px, $heading-font, $white-clr);
  }
  &:hover, &:focus{
    background: #4d4f51;
  }
}
.status-btn{
  background: rgba(33, 35, 38, 0.1);
  @include font($font-size-sm, $heading-font, $black-color);
  border: none;
  border-radius: $input-radius;
  padding: 9px 20px;
  text-transform: $capital;
  line-height: normal;
  @media (max-width: 767px) {
    padding: 8px 13px;
    flex: 1 auto;
  }
  &.manage-pad-mob{
    @media (max-width: 1385px) {
      padding: 8px 13px;
    }
  }
  &:hover, &:focus {
    background: rgba(33, 35, 38, 0.1);
    color: $black-color;
  }
  &.pending {
    color: $featured-clr;
    background: $featured-bg-clr;
  }
  &.completed, &.complete, &.clear{
    color: $succ-clr;
    background: rgba(87, 176, 127, 0.1);
  }
  &.published {
    color: $published-clr !important;
    background: $published-bg-clr !important;
  }
  &.rejected {
    color: $rejected-clr;
    background: $rejected-bg-clr;
  }
  &.inactive, &.red-color, &.misdemeanor, &.felony, &.consider {
    color: $rejected-clr;
    background: $rejected-bg-clr;
  }
  &.auto_rejected {
    color: $rejected-clr;
    background: $rejected-bg-clr;
  }
  &.cancelled {
    color: $rejected-clr;
    background: $rejected-bg-clr;
  }
  &.deleted  {
    color: $rejected-clr;
    background: $rejected-bg-clr;
  }
  &.background_felony {
    color: $rejected-clr;
    background: $rejected-bg-clr;
  }
  &.eviction {
    color: $rejected-clr;
    background: $rejected-bg-clr;
  }
  &.expired {
    color: $rejected-clr;
    background: $rejected-bg-clr;
  }
  &.featured {
    color: $featured-clr;
    background: $featured-bg-clr;
  }
  &.featured-btn {
    color: $featured-clr;
    background: $featured-bg-clr;
  }
  &.active{
    color: $succ-clr;
    background: rgba(87, 176, 127, 0.1);
    &:hover, &:focus{
      box-shadow: inherit;
    }
  }
  &.approved{
    color: $succ-clr;
    background: rgba(87, 176, 127, 0.1);
    &:hover, &:focus{
      box-shadow: inherit;
    }
  }
  &.background_clear  {
    color: $succ-clr;
    background: rgba(87, 176, 127, 0.1);
    &:hover, &:focus{
      box-shadow: inherit;
    }
  }
  &.background_misdemeanor {
    color: orange;
    background: rgba(250, 142, 2, 0.1);
    &:hover, &:focus{
      box-shadow: inherit;
    }
    min-width: 140px !important;
  }
  &.action-btn{
    cursor: pointer !important;
  }
  &.mob-mr-fix{
   @media (max-width: 767px) {
    margin: 15px 0 0 0;
   }
  }
}
.flex-end, .align-right{
  .status-btn{
    &.mob-mr-fix{
      @media (max-width: 767px) {
       margin: 0 0 0 15px;
      }
     }
  }
}

.white-btn{
  background: $white-clr;
  border-radius: $input-radius;
  text-transform: $capital;
  @include font($font-size-13, $bold-font-8, $primary-light-clr);
  width: 200px;
  padding: 15px;
  @include flexM(flex, center, center);
  position: relative;
  border: 1px solid transparent;
  overflow: hidden;
  z-index: 1;
  &:after{
    content: "";
    @include position(absolute, 0, 0, 100%, 0);
    @include gradient(45deg, $gradient-color);
    @include transition(all ease-in-out 0.3s);
    z-index: -1;
  }
  &:hover{
    border-color: $white-clr;
    color: $white-clr;
    background: $white-clr;
    &::after{
      width: 100%;
      @include transition(all ease-in-out 0.3s);
    }
  }
  &:focus{
    border-color: transparent;
    background: $white-clr;
    color: $white-clr;
  }
}
.magnta-btn{
  @include font($font-size-12, normal, $white-clr);
  @include flexM(flex, center, center);
  @include gradient(to right, $gradient-color);
  border-radius: $input-radius;
  text-transform: $capital;
  padding: 11px;
  min-width: 200px;
  position: relative;
  border: 1px solid transparent;
  overflow: hidden;
  z-index: 1;
  &:after{
    content: "";
    @include position(absolute, 0, 0, 100%, 0);
    background: $white-clr;
    @include transition(all ease-in-out 0.3s);
    z-index: -1;
  }
  &:hover{
    border-color: $primary-clr;
    color: $primary-clr !important;
    &::after{
      width: 100%;
      @include transition(all ease-in-out 0.3s);
    }
  }
  &:focus{
    border-color: $primary-clr;
  }
}
.button{
  @include font($font-size-14, normal, $white-clr);
  text-transform: $capital;
  padding: 0.844rem;
  border-radius: $input-radius;
  @include transition(ease-out 0.4s);
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: none;
  &:after{
    content: "";
    @include position(absolute, 0, 0, 100%, 100%);
    background: $white-clr;
    @include transition(all ease-in-out 0.3s);
    z-index: -1;
    transform: translateX(-100%);
  }
  &:hover{
    @include transition(all ease-in-out 0.3s);
    &::after{
      transform: translateX(0);
      @include transition(all ease-in-out 0.3s);
    }
  }
  &.btn-secondary{
    background: $gray-color;
    &:hover{
      box-shadow: 0 0 0 1px $gray-color;
      color: $gray-color;
      a{
        color: $gray-color;
      }
    }
    a{
      color: $white-clr;
    }
  }
  &.btn-primary{
    @include gradient(to right, $gradient-color);
    &:hover{
      box-shadow: 0 0 0 1px $primary-clr;
      color: $primary-clr;
      a{
        color: $primary-clr;
      }
    }
    a{
      color: $white-clr;
    }
  }
  &.btn-light{
    background: #efeff0;
    @include font($font-size-12, $heading-font, $primary-txt-clr);
    &:hover{
      box-shadow: 0 0 0 1px $primary-txt-clr;
      color: $primary-txt-clr;
    }
  }
  &.btn-danger{
    background: $error-clr;
    @include font($font-size-12, $heading-font, $white-clr);
    &:hover{
      box-shadow: 0 0 0 1px $error-clr;
      color: $error-clr;
    }
  }
  &.small-btn{
    font-size: $font-size-13;
    padding: 0.7rem;
  }
  &.light-purpple-bg{
    background: #f0e4f6;
    color: $primary-light-clr;
    font-weight: 600;
    font-size: $font-size-13;
    @media (max-width: 767px) {
      padding: 0.7rem;
      width: 100% !important;
      font-size: 12px;
    }
  }
  &.w-220{
    @media (max-width: 767px) {
     width: 100% !important;
    }
  }
}
.btn:active:focus, .btn:focus{
  box-shadow: none;
}
.btn.delete-btn, .btn.add-btn{
  width: 100%;
  max-height: 65px;
  @media (max-width: 767px) {
    height: 65px;
  }
}
.btn.delete-btn{
  background: rgba(208, 22, 22, 0.05);
}
.sync-btn.btn {

  background: rgba(181, 62, 240, 0.1);
  border: none;
  @include font(11px, bold, $primary-light-clr);
  text-transform: $capital;
  font-family: $font;
  min-width: 149px;
  border-radius: $input-radius;
  cursor: auto;
  @media (max-width: 767px) {
    width: 100%;
    margin: 15px 0;
    padding: 7px;
    margin-left: 0;
  }
  + .status-btn{
    @media (max-width: 767px) {
     margin-left: 0;
    }
  }
  &.w-210{
    min-width: 210px;
  }
  &.auto-w-mob{
    @media (max-width: 767px) {
      width: auto;
      margin: 0;
    }
  }
  &.cursor-pointer{
    cursor: pointer;
  }
}

.btn{
  &.feature-now, &.view-more{
    @include font($font-size-13, bold, $black-color);
    text-transform: $capital;
    padding: 11px 26px;
    border-radius: $input-radius;
    border: none;
    background: $white-clr;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after{
      content: "";
      @include position(absolute, 0, 0, 100%, 100%);
      background: #8d45c9;
      @include transition(all ease-in-out 0.3s);
      z-index: -1;
      transform: translateX(-100%);
    }
    &:hover{
      @include transition(all ease-in-out 0.3s);
      box-shadow: 0 0 0 1px $white-clr;
      color: $white-clr;
      &::after{
        transform: translateX(0);
        @include transition(all ease-in-out 0.3s);
      }
    }
  }
  &.view-more{
    @include font($font-size-13, bold, $white-clr);
    background: rgba(255, 255, 255, 0.1);
    &:after{
      background: $white-clr;
    }
    &:hover{
      color: $black-color;
      @include transition(all ease-in-out 0.3s);
      &::after{
        transform: translateX(0);
        @include transition(all ease-in-out 0.3s);
      }
    }
  }
}