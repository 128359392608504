.table-head{
    background: rgba(33, 35, 38, 0.05);
    border-radius: $input-radius;
    margin-bottom: 30px;
    padding: 20px;
    .table-col{
        &.align-right{
            @include flexM(flex, center, flex-end);
        }
        span{
            @include font($font-size-sm, $heading-font, $gray-color);
            text-transform: $capital;
            @include flexM(flex, center, flex-start);
            img{
                margin-left: 4px;
            }
        }
    }
}
.table-body{
    background: $white-clr;
    border-radius: $input-radius;
    margin-bottom: 20px;
    padding: 20px;
    position: relative;
    &.gray-bg{
        background: rgba(33, 35, 38, 0.05) !important;
    }
    .table-col{
        display: flex;
        align-items: center;
        height: 100%;
        &.align-right{
            @include flexM(flex, center, flex-end);
            height: 100%;
            &.mob-align-left{
                @media (max-width: 767px) {
                    align-items: flex-start;
                }
            }
            &.align-right{
                max-height: 80px;
            }
            p{
                width: auto;
            }
            &.mob-align-left{
                @include size(tablet-up){
                    justify-content: flex-start;
                    margin: 10px 0;
                }
            }
        }
        &.flex-start{
            align-items: flex-start;
            p{
                line-height: 26px;
            }
        }
        .prop-img{
            display: flex;
            margin-right: 10px;
            overflow: hidden;
            border-radius: 6px;
            min-width: 40px;
            &.mr-r-20{
                margin-right: 20px;
                @media (max-width: 420px){
                    margin-right: 12px;
                }
            }
            img{
                width: 40px;
                height: 40px;
                object-fit: cover;
                @media (max-width: 767px){
                    min-width: 40px;
                }
            }
        }
        .prop-detail{
            display: flex;
            flex-flow: column;
            width: calc(100% - 50px);
            @media (max-width: 420px){
                width: calc(100% - 40px);
            }
            h4{
                @include font($font-size-md, $semi-font, $black-color);
                margin-bottom: 5px;
                line-height: 1.54;
                @include ellipsis-text(nowrap, hidden, ellipsis, 80%);
                @media (max-width: 420px){
                   width: 100%;
                   white-space: break-spaces;
                   overflow: inherit;
                }
                i{
                    font-style: normal;
                    margin-left: 10px;
                    padding-left: 10px;
                    position: relative;
                    &::after{
                        content: "";
                        @include position(absolute, 2px, 0, auto, auto);
                        border-left: 1px solid #aaaaaa;
                        bottom: 3px;
                    }
                }
                &.no-eclipse{
                    @include ellipsis-text(normal, visible, ellipsis, 100%);
                }
            }
            p{
                @include font($font-size-13, normal, $gray-color);
                @include ellipsis-text(nowrap, hidden, ellipsis, 80%);
                @media (max-width: 420px){
                    width: 92%;
                 }
                &.no-eclipse{
                    @include ellipsis-text(normal, visible, ellipsis, 100%);
                    word-break: break-word;
                }
                &.subheading{
                    @media (max-width: 600px){
                        white-space: inherit;
                        line-height: 16px;
                     }
                }
            }
        }
        .list-action{
            .dropdown-toggle{
                background: transparent;
                border: none;
                box-shadow: none;
                &::after{
                    display: none;
                }
                @include size(tablet-up){
                    padding: 0;
                }
            }
            .dropdown-menu{
                border: none;
                border-radius: $input-radius;
                box-shadow: 0px 2px 8px 0 rgba(33, 35, 38, 0.2);
                margin-top: 18px;
                min-width: 220px;
                .dropdown-item{
                    padding: 0.625rem 1.25rem;
                    @include flexM(flex, center, flex-start);
                    @include font($font-size-15, normal, $gray-color);
                    img{
                        margin-right: 10px;
                    }
                    &:hover, &:focus{
                        background-color: $light-gray;
                    }
                }
            }
        }
        p{
            @include font($font-size-13, normal, $gray-color);
            @include ellipsis-text(nowrap, hidden, ellipsis, 85%);
            &.no-eclipse{
                @include ellipsis-text(normal, visible, ellipsis, 100%);
            }
            &.role{
                color: $primary-light-clr;
            }
            &.homeowner{
                color: $blue-clr;
            }
            &.both{
                color: $succ-clr;
            }
        }
        .room-detail{
            display: flex;
            li{
                margin: 0;
                list-style: none;
                @include font($font-size-13, normal, $gray-color);
                position: relative;
                padding-left: 12px;
                margin-left: 10px;
                &::before{
                    content: "";
                    @include position(absolute, 4px, 0, 5px, 5px);
                    border-radius: 50%;
                    background: #cecfd1;
                }
                &:first-of-type{
                    padding-left: 0;
                    margin-left: 0;
                    &::before{
                        display: none;
                    }
                }
            }
        }
        &.separator-line{
            @media (max-width: 767px){
                padding-bottom: 40px;
                position: relative;
                &::after{
                    content: "";
                    @include position(absolute, auto, -20px, auto, auto);
                    border-bottom: 1px solid #f3f3f4;
                    bottom: 19px;
                    right: -20px;
                }
            }
        }
        strong{
            @include font($font-size-14, $heading-font, $primary-txt-clr);
        }
        &.buttons-column{
            flex-flow: row wrap;
            button{
                margin-top: 3px;
                margin-bottom: 3px;
                margin-left: 0;
            }
        }
    }
    .status-btn{
        border-radius: 15px;
        padding: 10px 15px;
        min-width: 120px;
        cursor: auto;
        @include size(phone){
            min-width: 100px;
            padding: 10px 16px;
        }
        @media (max-width: 1280px) and (min-width: 1180px) {
            min-width: inherit;
            padding: 10px 16px;
        }
        &:first-of-type{
            margin-right: 5px;
        }
    }
    .view-action{
        @include font($font-size-14, normal, $primary-light-clr);
        text-decoration: underline;
        cursor: pointer;
    }
    &:empty{
        display: none;
    }
    .row-with-pad-border{
        border-bottom: 1px solid #e1e1e1;
        padding: 12px 0;
        font-size: 14px;
        p{
            @include size(phone){
                line-height: 22px;
            }
        }
        .col-md-4{
            text-align: right;
            font-style: italic;
            @include size(phone){
                text-align: left;
                margin-top: 10px;
            }
        }
        &:last-of-type{
            border-bottom: none;
        }
    }
    &.light-gray-bg{
        .prop-detail{
            h4{
                margin-bottom: 0;
                line-height: 22px;
            }
            p{
                line-height: 22px;
            }
        }
    }
    .two-button{
        .table-col{
            flex-flow: row wrap;
            button{
                &:last-of-type{
                    @include size(phone){
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .lease-signor-action{
        @include size(phone){
            margin: 0;
        }
        .status-btn{
            @include size(tablet-up){
                min-width: inherit;
            }
            @include size(phone){
                min-width: inherit;
                padding: 8px 12px;
                margin: 0;
            }
            &:last-of-type{
                @include size(phone){
                    margin-left: 5px;
                }
            }
        }
    }
    .price-btn{
        cursor: auto;
    }
    .add-position-mob{
        @media (max-width: 767px) {
            position: absolute;
            top: 3px;
            right: 20px;
        }
    }
}
.bank-table{
    .row{
        .col, .col-2{
            &:first-of-type{
                max-width: 70px;
            }
            &:nth-child(2){
                min-width: 220px;
            }
            &:last-of-type{
                max-width: 45px;
            }
        }
        .delete-btn{
            width: 24px;
            height: 24px;
            border: none;
            @include flexM(flex, center, center);
            margin: auto;
            @media (max-width: 767px){
                display: inline-flex;
            }
            img{
                max-width: 18px;
            }
        }
        .show-info {
            @include flexM(inline-flex, center, center);
            width: 24px;
            height: 24px;
            margin-right: 5px;
            border: none;
            background: $light-gray;
        }
    }
    &.hidden-in-mob{
        @media (max-width: 767px){
            display: none;
        }
    }
    &.show-in-mob{
        @media (min-width: 767px){
            display: none;
        }
    }
}
.striped-table{
    &.bg-gray{
      background: $light-gray;
      padding: 30px;
      border-radius: $input-radius;
    }
    .t-head{
      h4{
        @include font($font-size-h3, $bold-font-8, $primary-txt-clr);
      }
      .align-right{
        @include flexM(flex, center, flex-end);
        @include font($font-size-md, $med-font, $gray-color);
      }
    }
    .t-body{
      border-bottom: 1px solid rgba(33, 35, 38, 0.1);
      padding: 15px 0;
      p{
        @include font($font-size-md, $med-font, $gray-color);
        &.align-right{
          text-align: right;
        }
      }
      b{
        @include font($font-size-md, $heading-font, $primary-light-clr);
        &.align-right{
          @include flexM(flex, center, flex-end);
        }
        &.red-color{
          color: $error-clr;
        }
      }
    }
  }
.notification{
    .table-col{
        .prop-img{
            img{
                max-width: 24px;
                max-height: 24px;
                object-fit: contain;
            }
        }
    }
}
.tab-table-head{
   background: rgba(33, 35, 38, 0.05);
   padding: 15px 20px;
   border-radius: $input-radius;
   .table-item{
    @include flexM(flex, center, flex-start);
    height: 100%;
       h5{
        @include font($font-size-15, $med-font, $gray-color);
       }
   }
}
.tab-table-body{
  padding: 10px 20px;
  border-bottom: 1px solid #f1f1f1;
  .table-item{
    @include flexM(flex, center, flex-start);
    height: 100%;
    p{
     @include font($font-size-14, $med-font, $gray-color);
    }
    img{
        cursor: pointer;
    }
    }
}
.form-container{
    .lease-signor-block{
        .mob-bank-table{
            .table-item{
                margin-bottom: 0;
                min-height: 30px;
                align-items: center;
                display: flex;
                h5{
                    margin-bottom: 0;
                    @include size(phone){
                        font-size: 13px;
                    }
                }
                .make-default{
                    color: $primary-light-clr;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        &.hotel-pay-pg{
           .header-block {
            > .row{
                @media (max-width: 991px) {
                    flex-direction: column;
                }
            }
           }
        }
    }
}
.renter-pricing-detial{
	.price-breakdown{
		margin-bottom: 30px;
		.item{
			border-bottom: 1px solid rgba(33, 35, 38, 0.1);
			padding: 15px 0;
			p{
				@include font($font-size-md, $med-font, $gray-color);
			}
			.flex-end{
				text-align: right;
			}
		}
	}
}
.add-spaceing--in-mob{
    .mob-mr-fix{
        @include size(tablet-up){
            margin: 15px 0 0 0 !important;
        }
    }
}
