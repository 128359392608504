.user-widget{
  .main-header{
    .right-navbar{
      .mobile-menu{
        @include size(tablet-up){
          display: none;
        }
      }
      .user-info{
        display: flex;
        position: relative;
        cursor: pointer;
        .user{
          .user-txt{
            @include size(tablet-up){
              display: none;
            }
          }
        }
        .filter-dropdown{
          left: auto;
          right: 0;
          margin-top: 11px;
          a{
            line-height: 24px;
          }
        }
      }
      .nav-links{
        display: none;
      }
    }
  }
}
.background-check-sec{
  padding: 40px 0 100px;
  @include size(tablet){
    padding: 40px 0;
  }
  .mob-col-reverse{
    @include size(tablet-up){
      flex-flow: column-reverse;
    }
  }
  .button{
    @include size(phone){
      font-size: $font-size-12;
    }
  }
}
.back-check-heading{
  margin-bottom: 40px;
  @include size(tablet-up){
    margin-bottom: 30px;
  }
  h2{
    @include font($font-size-lg, $heading-font, $black-color);
    margin-bottom: 5px;
    @include size(tablet-up){
      font-size: $font-size-20;
    }
  }
  p{
    @include font($font-size-15, normal, $gray-color);
    @include size(tablet-up){
      line-height: 24px;
      font-size: $font-size-14;
    }
  }
}
.back-check-form{
  padding: 42px 50px;
  border-radius: $input-radius;
  box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.02);
  background-color: $white-clr;
  margin-bottom: 15px;
  @include size(tablet-up){
    padding: 30px 15px;
  }
  .heading{
    h2{
      @include font($font-size-20, $heading-font, $black-color);
      margin-bottom: 5px;
    }
    p{
      @include font($font-size-15, normal, $gray-color);
      margin-bottom: 30px;
    }
  }
  .sub-heading{
    margin: 20px 0;
    h5{
      @include font($font-size-20, $heading-font, $black-color);
      margin-bottom: 5px;
    }
    p{
      @include font($font-size-15, normal, $gray-color);
    }
  }
  .status-btn{
    width: 100%;
    margin-bottom: 30px;
    font-size: $font-size-14;
  }
}
.app-process-detail{
  padding: 40px;
  margin-left: 6px;
  @include size(tablet-up){
    padding: 30px 15px;
    margin-left: 0;
  }
  border-radius: 4px;
  box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
  background-color: $white-clr;
  h4{
    @include font($font-size-18, $heading-font, $primary-txt-clr);
    margin-bottom: 23px;
  }
  ul{
    padding-left: 15px;
    li{
      @include font($font-size-14, normal, $primary-txt-clr);
      line-height: 24px;
      margin-bottom: 25px;
      padding-left: 15px;
    }
  }
  .app-fee{
    display: flex;
    background-color: $light-gray;
    border-radius: $input-radius;
    padding: 17px 20px;
    margin: 36px 0 30px;
    p{
      @include font($font-size-16, normal, $primary-txt-clr);
      width: 50%;
    }
    strong{
      @include font($font-size-16, $heading-font, $primary-txt-clr);
      @include flexM(flex, center, flex-end);
      width: 50%;
    }
  }
}
.upload-license{
  padding: 17px 15px;
  border-radius: $input-radius;
  background-color: $light-gray;
  margin-bottom: 20px;
  h5{
    @include font($font-size-15, normal, $primary-txt-clr);
    margin-bottom: 5px;
    @include size(tablet-up){
      font-size: $font-size-14;
      line-height: 22px;
    }
  }
  p{
    @include font($font-size-12, normal, $primary-txt-clr);
    margin-bottom: 5px;
  }
}
.form-button{
  @include flexM(flex, center, flex-end);
  @include size(tablet-up){
    flex-direction: column;
  }
  .button{
    font-size: $font-size-12;
    padding: 0.7rem;
    width: 150px;
    &.btn-primary{
      width: 225px;
      margin-left: 15px;
      @include size(tablet-up){
        margin-left: 0;
        margin-top: 15px;
      }
    }
    @include size(tablet-up){
      min-width: 100%;
    }
  }
}
.prop-booked-sec{
  padding: 55px 0 100px;
}
.prop-booked{
  max-width: 540px;
  margin: auto;
  box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
  background-color: $white-clr;
  border-radius: $border-r-4;
  .header{
    padding: 50px 50px 30px;
    text-align: center;
    img{
      margin-bottom: 15px;
    }
    h2{
      @include font($font-size-lg, $heading-font, $primary-txt-clr);
    }
  }
  .booking-detail{
    padding: 20px 28px;
    border-bottom: 1px solid #e3e3e3;
    strong{
      @include font($font-size-14, $heading-font, $gray-color);
      line-height: 1.85;
    }
    p{
      @include font($font-size-13, normal, $gray-color);
      line-height: 1.85;
    }
  }
  .btn-wrp{
    padding: 20px 60px;
  }
  .accord-sm{
    padding: 13px 35px 20px;
    .accordion-item{
      margin-bottom: 0;
      padding: 5px;
      box-shadow: inherit;
      .accordion-header{
        button{
          @include font($font-size-13, $med-font, $black-color);
        }
      }
      .accordion-body{
        @include font($font-size-13, normal, $gray-color);
      }
    }
  }
}
.booking-pg-pay-table{
  padding: 30px;
  border-radius: $input-radius;
  margin-bottom: 30px;
    .heading {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        display: flex;
        padding-bottom: 30px;
        .user-thumb{
          background: rgba(33, 35, 38, 0.1);
          min-width: 40px;
          height: 40px;
          @include flexM(flex, center, center);
          margin-right: 20px;
          border-radius: $input-radius;
        }
        .title{
            h4{
                @include  font($font-size-15, $med-font, $primary-txt-clr);
                @media (max-width: 767px) {
                  margin-top: 10px;
                }
            }
            p{
                @include  font($font-size-13, normal, $gray-color);
                line-height: 30px;
            }
        }
        .action-btn{
          @media (max-width: 767px) {
            margin-top: 20px;
          }
        }
    }
    .table-item{
      h5{
        @include  font($font-size-14, normal, $gray-color);
        @media (max-width: 420px) {
          font-size: 13px;
        }
      }
      p{
        @include  font($font-size-15, $med-font, $primary-txt-clr);
      }
      &.flex-end{
        display: flex;
        justify-content: end;
      }
    }
    .align-right{
      display: flex;
        justify-content: end;
    }
    .pad-tp-20{
      padding-top: 20px;
    }
    .border-btm{
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 20px 0;
      .gx-0{
        --bs-gutter-x: 1.5rem;
      }
    }
}
.view-renter-agreement{
  margin: 30px 0 50px;
  a{
    @include font($font-size-14, normal, $primary-light-clr);
    line-height: 24px;
    text-decoration: underline;
  }
}
.occupant-wrp{
  .col-md-12{
    margin-top: 25px;
    display: flex;
    &:first-of-type{
      margin-top: 0;
      .occupants-field{
        width: 100%;
      }
    }
    .occupants-field{
      width: calc(100% - 90px);
      margin-bottom: 0;
      @include size(phone){
        width: calc(100% - 80px);
      }
    }
  }
  .btn-danger{
    background-color: rgba(255, 0, 0, .1);
    border: none;
    outline: none;
    min-width: 65px;
    margin-left: 25px;
    @include size(phone){
      margin-left: 15px;
    }
  }
  .error-message{
    margin-top: 10px;
  }
}
.user-management-filter{
  display: flex;
  .choose-country{
    width: 130px;
    min-width: 105px;
    .react-select{
      width: 100%;
      .css-1s2u09g-control, .css-13cymwt-control{
        background-color: white;
      }
    }
  }
  .search-field{
    display: flex;
    flex-flow: row wrap;
    width: 70%;
    p{
      margin-left: 15px;
      font-size: 13px !important;
      white-space: nowrap;
    }
  }
  .form-floating{
    margin-left: 15px;
    width: 100%;
  }
}
.custom-background-check {
  label {
    width: unset;
  }
}