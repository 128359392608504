.left-sidebar{
    .logo{
        padding: 60px 36px 70px 36px;
    }
    h6{
        @include font($font-size-sm, $med-font, $white-clr);
        padding: 0 20px 16px 20px;
        text-transform: $capital;
    }
    .nav{
        ul{
            width: 100%;
            li{
                list-style: none;
                a{
                    @include font($font-size-md, $med-font, $white-clr);
                    padding: 0.95rem 1.5rem;
                    @include transition(all ease-in-out 0.2s);
                    position: relative;
                    cursor: pointer;
                    &:hover, &.active-route{
                        @include transition(all ease-in-out 0.2s);
                        background: rgba(197, 198, 202, 0.1);
                        color: $white-clr;
                        img{
                            opacity: 1;
                        }
                    }
                    img{
                        opacity: 0.8;
                    }
                    .title{
                        margin-left: 10px;
                        &.d-none{
                            + .badge-count{
                            left: 75%;
                            }
                        }
                    }
                    .title-content{
                        position: relative;
                        .badge-count{
                            position: absolute;
                            top: -10px;
                            left: 95%;
                            background-color: #e3091e;
                            font-size: 11px;
                            padding: 3px 6px;
                            border-radius: 10px;
                        }
                    }
                }
                &.Profile{
                    a{
                        padding: 0.8rem 1.2rem;
                        img{
                            border-radius: $input-radius;
                            width: 30px;
                            height: 30px;
                            object-fit: cover;
                            opacity: 1;
                        }
                    }
                }
                &.nav-toggle{
                    margin-top: 30px;
                    :not(.Tooltip-Wrapper){
                        .Tooltip-Tip{
                            display: none;
                        }
                    }
                }
            }
        }
        &.no-list{
            ul{
                counter-reset: item;
                li{
                    a{
                        counter-increment: item;
                        padding: 1.25rem 1.5rem 1.25rem 3.75rem;
                        pointer-events: none;
                        &::before{
                            content: counter(item);
                            background: rgba(187, 140, 140, 0.8);
                            @include flexM(flex, center, center);
                            border-radius: 50%;
                            @include font($font-size-sm, $e-bold-font, $primary-light-clr);
                            @include position(absolute, 17px, 25px, 21px, 21px);
                        }
                        &:hover, &.active-route{
                            cursor: pointer;
                            pointer-events: auto;
                            &::before{
                                background: $white-clr;
                            }
                        }
                        &.is-done{
                            cursor: pointer;
                            pointer-events: auto;
                            &::before{
                                background: $succ-clr url('../../img/tick-icon.svg') center no-repeat;
                                color: transparent;
                                background-size: 17px;
                                background-position: center;
                            }
                        }
                    }
                }
            }
        }
        .dashboard-nav{
            width: calc(100% - 20px);
            margin: 0 10px;
            li{
                a{
                    padding: 10px 13px;
                    @include font($font-size-p, 300, rgba(255, 255, 255, 0.7));
                    @include flexM(flex, center, flex-start);
                    border-radius: $input-radius;
                    margin-bottom: 10px;
                    &::before{
                        display: none;
                    }
                    img{
                        margin-right: 20px;
                    }
                    &:hover, &.is-active{
                        color: $white-clr;
                        img{
                            color: $rejected-clr;
                        }
                    }
                }
            }
        }
    }
    &.owner-nav{
        .logo{
            padding: 4.375rem 1.25rem;
        }
    }
}
.mobile-nav-wrp{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    &.active{
        opacity: 1;
        visibility: visible;
        .mobile-nav{
            margin-top: 70px;
            @include transition(all ease-in-out 0.2s);
        }
    }
    nav.mobile-nav {
        background: $white-clr;
        border-radius: $input-radius;
        margin: 170px 15px 0;
        @include transition(all ease-in-out 0.2s);
        ul{
            padding: 0.625rem 0;
            li{
                list-style: none;
                &.Profile{
                    display: none;
                }
                a{
                    @include font($font-size-15, normal, $primary-txt-clr);
                    padding: 0.625rem 1.25rem;
                    img{
                        filter: brightness(0.5);
                        margin-right: 10px;
                    }
                }
                &.active-country, &.arrow-left{
                    position: relative;
                    img{
                        position: absolute;
                        right: 14px;
                        width: 25px;
                        height: 25px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
        &.no-list{
            ul{
                counter-reset: item;
                li{
                    a{
                        counter-increment: item;
                        &::before{
                            content: counter(item);
                            background: $primary-light-clr;
                            @include flexM(inline-flex, center, center);
                            border-radius: 50%;
                            @include font($font-size-sm, 600, $white-clr);
                            width: 21px;
                            height: 21px;
                            margin-right: 10px;
                            font-family: $font;
                        }
                        &:hover, &.active-route{
                            &::before{
                                background: $primary-light-clr;
                            }
                        }
                        &.is-done{
                            &::before{
                                background: $succ-clr url('../../img/tick-icon.svg') center no-repeat;
                                color: transparent;
                                background-size: 17px;
                                background-position: center;
                            }
                        }
                    }
                }
            }
        }
        &.home-pg{
            padding: 30px 0 20px;
            strong{
                @include font($font-size-12, $heading-font, $primary-light-clr);
                text-transform: $capital;
                padding: 0 1.25rem;
                display: flex;
            }
            ul{
                img{
                    filter: inherit !important;
                }
            }
        }
    }
    .button{
        margin: 0 1.25rem;
        width: calc(100% - 2.50rem);
        &.mr-b-20{
            margin-bottom: 1.25rem;
        }
    }
    .close-nav{
        display: flex;
        width: 40px;
        height: 40px;
        margin: 40px auto 0;
        border-radius: 50%;
        background: #313131;
        padding: 8px;
    }
}
