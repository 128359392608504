.login-wrapper{
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    .left-side{
        width: 500px;
        padding: 3.125rem;
        @include flexM(flex, center, flex-start);
        @media (max-width: 767px) {
            width: 100%;
            flex-flow: column;
            padding: 20px;
            align-items: inherit;
        }
        .mobile-header{
            @include flexM(flex, center, flex-start);
            margin: 1rem 0 4.063rem 0;
            img{
                display: flex;
            }
        }
        @media (min-width: 767px) {
            .mobile-header{
                display: none;
            }
        }
        .form-wrp{
            width: 100%;
            .headind-sec{
                h2{
                    @include font($font-size-lg, $bold-font-8, $primary-txt-clr);
                    margin-bottom: 8px;
                }
                p{
                    @include font($font-size-13, normal, $gray-color);
                    line-height: 1.69;
                }
            }
            .forget-pass{
                text-align: right;
                a{
                    @include font($font-size-p, normal, $primary-txt-clr);
                    cursor: pointer;
                    &:hover{
                        text-decoration: underline;
                        color: $primary-light-clr;
                    }
                }
            }
            .button{
                height: 50px;
                width: 100%;
                font-size: $font-size-md;
                font-weight: $med-font;
            }
            .no-account{
                text-align: center;
                a{
                    @include font($font-size-p, normal, $primary-light-clr);
                    text-decoration: underline;
                    cursor: pointer;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
            &.text-center{
                text-align: center;
                .check-icon{
                    @include flexM(flex, center, center);
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    box-shadow: 2px 5px 40px 0 rgba(0, 0, 0, 0.1);
                    margin: auto auto 43px auto;
                }
                p{
                    font-size: $font-size-p;
                    max-width: 400px;
                    margin: auto;
                    line-height: 26px;
                }
            }
            .choose-option{
                display: flex;
                .custom-checkbox{
                    width: auto;
                    margin-right: 20px;
                    font-size: 15px;
                    @media (max-width: 350px){
                        font-size: 14px;
                    }
                }
            }
            #password{
                padding-right: 50px;
            }
        }
        .map-input{
            display: flex;
            .column-1{
                flex: 1;
                margin-right: 13px;
                @media (max-width: 420px){
                    margin-right: 5px;
                }
                input{
                    border: 1px solid #ccc;
                    background: #f7f7f7;
                    padding: 15px;
                    border-radius: 4px;
                    text-align: center;
                    @include font($font-size-20, $heading-font, $primary-txt-clr);
                    width: 100%;
                    outline: none;
                    @media (max-width: 420px){
                        padding: 10px;
                        font-size: 18px;
                    }
                    &:focus{
                        border-color:#69298b;
                    }
                }
                &:last-of-type{
                    margin-right: 0;
                }
            }
            + .error-message{
                @include size(tablet-up){
                    margin-top: 10px;
                }
            }
        }
        .d-flex{
            @media (max-width: 421px){
                flex-direction: column;
            }
            @media (max-width: 350px){
                flex-direction: column;
             }
        }
        .edit{
            width: calc(100% - 127px);
            display: inline-flex;
            position: relative;
            @media (max-width: 421px){
                width: 100%;
            }
            @media (max-width: 350px){
                width: 100%;
            }
            input{
                border: none;
                background: rgba(181, 62, 240, 0.05);
                border-radius: $input-radius;
                @include font($font-size-md, $semi-font, $primary-txt-clr);
                padding: 14px 20px;
                height: auto;
                @media (max-width: 420px){
                    padding: 14px 10px;
                 }
                &:last-of-type{
                    margin-right: 0;
                }
                &:hover{
                    box-shadow: inherit;
                }
                &:focus{
                    box-shadow: inherit;
                }
            }
            a{
                position: absolute;
                right: 20px;
                top: 16px;
                @include font($font-size-md, $semi-font, $primary-txt-clr);
                text-transform: $capital;
                cursor: pointer;
                color: $primary-light-clr;
                @media (max-width: 420px){
                    right: 10px;
                 }
            }
            .App{
                pointer-events: none;
                width: 152px;
                margin-right: 12px;
                .css-1s2u09g-control, .css-13cymwt-control{
                    border: none;
                    background: rgba(181, 62, 240, 0.05);
                    border-radius: $input-radius;
                    min-height: 48px;
                }
                .css-tlfecz-indicatorContainer, .css-1xc3v61-indicatorContainer{
                    padding: 0;
                }
                .css-qc6sy-singleValue{
                    min-width: 55px;
                }
            }
        }
        .status-btn{
            height: 48px;
            font-size: 13px;
            margin-left: 13px;
            margin-right: 0;
            @media (max-width: 421px){
                margin-left: 0;
                margin-top: 15px;
            }
            @media (max-width: 350px){
               margin-left: 0;
               margin-top: 15px;
            }
        }
        .note-txt{
            p{
                @include font($font-size-md, normal, $gray-color);
                line-height: 1.57;
                b{
                    font-weight: $bold-font;
                }
            }
        }
        .choose-item{
            display: flex;
            padding: 35px 20px;
            border-radius: $input-radius;
            box-shadow: 2px 5px 40px 0 rgba(0, 0, 0, 0.1);
            align-items: center;
            border: 1px solid transparent;
            cursor: pointer;
            @include transition(all ease-in-out 0.2s);
            .img{
                margin-right: 25px;
                width: 90px;
                text-align: center;
            }
            .txt{
                width: calc(100% - 115px);
                h5{
                    @include font($font-size-20, $heading-font, $primary-txt-clr);
                    margin-bottom: 6px;
                }
                p{
                    @include font($font-size-13, normal, $gray-color);
                    line-height: 1.69;
                    max-width: 200px;
                }
            }
            &.continue-with-us{
                padding: 12px 25px;
                border: solid 1px rgba(40, 46, 66, 0.15);
                box-shadow: inherit;
                @include transition(all ease-in-out 0.2s);
                @media (max-width: 767px) {
                    min-height: 46px;
                    position: relative;
                }
                .img{
                    text-align: left;
                    @media (max-width: 767px) {
                        position: absolute;
                        top: 11px;
                        left: 20px;
                        width: auto;
                    }
                    img{
                        max-width: 20px;
                    }
                }
                .txt{
                    @media (max-width: 767px) {
                        width: 100%;
                        text-align: center;
                    }
                    h5{
                        @include font($font-size-md, $med-font, $primary-txt-clr);
                        margin-bottom: 0;
                    }
                }
            }
            &.fb{
                padding: 7px 9px;
                border-radius: 4px;
                @media (max-width: 767px) {
                    min-height: 40px;
                }
            }
            &.google{
                padding: 0;
                border: 0;
            }
            &:hover, &.active{
                border-color: $primary-light-clr;
                @include transition(all ease-in-out 0.2s);
            }
            .fb-item{
                width: 100%;
                button{
                    width: 100%;
                    display: flex;
                }
                .facebook-container{
                    @include font($font-size-md, $med-font, $primary-txt-clr);
                    display: flex;
                    align-items: center;
                    width: 100%;
                    img{
                        max-width: 24px;
                    }
                    span{
                        display: flex;
                        width: 100%;
                        justify-content: center;
                    }
                }
            }
        }
        .input-2{
            display: flex;
            .column-1 {
                width: 49%;
                margin-right: 20px;
                &:last-of-type{
                    margin-right: 0;
                }
            }
        }
        .check-box{
            margin-bottom: 26px;
            display: flex;
            align-items: flex-start;
            .white-block{
                display: inline-flex;
                margin: 2px 3px 0 0;
            }
            label{
                @include font($font-size-12, normal, $gray-color);
                line-height: 1.67;
                a{
                    color: $primary-light-clr;
                    text-decoration: underline;
                }
            }
        }
        .button-2{
            display: flex;
            @media (max-width: 479px){
                flex-flow: inherit;
            }
            .btn-secondary, .back-btn{
                width: 35%;
                margin-right: 10px;
            }
            .btn-primary {
                width: 65%;
            }
        }
    }
    .right-side{
        width: calc(100% - 500px);
        background-size: cover !important;
        @include flexM(flex, baseline, center);
        position: relative;
        overflow: hidden;
        &::before{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            background: url(../../img/sign-in-banner.jpg);
            height: 100vh;
            min-height: 914px;
            z-index: 0;
            background-position: top center;
            background-size: cover;
        }
        a{
            width: 250px;
            margin-top: 25%;
            position: relative;
            img{
                width: 100%;
            }
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
}

.document-verification{
    background-color: #f8f8f8;
    min-height: 100vh;
    display: flex;
    .main-wrp{
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        .logo{
            margin: 50px 0 30px;
            text-align: center;
        }
    }
    .button-wrp{
        margin: 50px 0;
        .button{
            min-width: 400px;
            margin: 0 auto;
            display: block;
            @media (max-width: 767px) {
                min-width: 100%;
            }
        }
    }
    .lease-signor-doc{
        background-color: $white-clr;
        border-radius: $input-radius;
        overflow: hidden;
        img{
            width: 100%;
        }
    }
}
.lease-doc-signed-success{
    background-color: #f8f8f8;
    min-height: 100vh;
    padding:100px 0;
    .logo{
        text-align: center;
    }
    .login-wrapper{
        min-height: auto;
        .left-side{
            width: 520px;
            margin: 0 auto;
        }
        .right-side{
            display: none;
        }
        .table-body{
            text-align: left;
            h4{
                margin-bottom: 0 !important;
                line-height: normal !important;
                margin-top: 3px;
            }
            p{
                margin: 0 !important;
            }
        }
    }
}