// Var & mixin style includes
@import "./abstracts/var";
@import "./abstracts/mixin";

// Base style includes
@import "./base/fonts";
@import "./base/global";

// Components style includes
@import "./components/button";
@import "./components/forms";
@import "./components/modal";
@import "./components/pagination";
@import "./components/tabs";
@import "./components/table";
@import "./components/stripe_element";
@import "./components/slick_theme";

// Layout components
@import "./layout/left-sidebar";
@import "./layout/right-sidebar";
@import "./layout/listing_table";

// Pages components
@import "./pages/sign-in";
@import "./pages/home-page";
@import "./pages/about-us";
@import "./pages/contact-us";
@import "./pages/privacy-policy";
@import "./pages/search-result.scss";
@import "./pages/property-detail";
@import "./pages/background-check";
@import "./pages/booking-payment";

//React Datepicker Css
@import "react-datepicker/dist/react-datepicker.css";
@import "react-datepicker/dist/react-datepicker-cssmodules.css";

//Video player Css
@import "~video-react/styles/scss/video-react";


.main-wrapper {

  
  // background-color: $primary-clr;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  .mobile-header {
    display: flex;
    width: 100%;
    padding: 20px 0;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1050;
    background: $primary-clr;
    @media (min-width: 767px) {
      display: none;
    }
    .logo {
      height: 100%;
      align-items: center;
      display: flex;
    }
    .right-header {
      @include flexM(flex, center, flex-end);
      .prof-pic {
        margin-left: 25px;
        &:empty {
          display: none;
        }
        img {
          width: 25px;
          height: 25px;
          object-fit: cover;
          border-radius: 4px;
        }
      }
    }
  }
  .left-sidebar {
    background-color: $primary-clr;
    flex: 0 0 auto;
    width: 260px;
    &.dashboard {
      width: 280px;
      position: fixed;
      left: 0;
      top: 0;
      .nav{
        height: calc(100vh - 180px);
        overflow-y: auto;
      }
    }
    &.owner-nav {
      width: 70px;
      position: relative;
      .logout {
        display: block;
      }
      &.collapse-out{
        @media (min-width: 768px) {
          width: 240px;
        }
        .nav-toggle{
          a{
            img{
              @media (min-width: 768px) {
                transform: rotate(180deg);
              }
            }
          }
        }
        + .right-sidebar{
          &.owner-dashboard{
           @media (min-width: 768px) {
            width: calc(100% - 240px);
           }
          }
        }
      }
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .right-sidebar {
    flex: 0 0 auto;
    width: calc(100% - 260px);
    &.dashboard {
      width: calc(100% - 280px);
      margin-left: 280px;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    &.owner-dashboard {
      width: calc(100% - 70px);
      background-color: $primary-clr;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
.disabled-link {
  pointer-events: none;
}
.h-160 {
  height: 160px !important;
}

.loading-image {
  filter: blur(5px);
  clip-path: inset(0);
}
.loaded-image {
  filter: blur(0px);
  transition: filter 0.5s linear;
}
.crusor-pointer{
  cursor: pointer !important;
}
.mob-align-left{
  .view-list{
    margin-left: 0 !important;
  }
}
.click-here-text{
margin-top: 20px;
a{
text-decoration: underline !important;
cursor: pointer !important;
color:#007bff !important ;
}
}
.add-mr-right{
  margin-right: 15px;
  cursor: pointer !important;
}
.react-datepicker-wrapper{
  width: 100%;
  .react-datepicker__input-container{
    width: 100%;
  }
}