.prop-detail-thumbs{
  position: relative;
  @media (min-width: 768px){
    padding-top: 20px;
  }
  .container{
    position: relative;
  }
  .mr-b-30{
    margin-bottom: 15px !important;
  }
  .row{
    @media (max-width: 620px){
      --bs-gutter-x: 0.625rem;
    }
  }
  .col-md-4{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .thumb-item{
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    aspect-ratio: 1.9;
    height: auto;
    margin-bottom: 0.625rem;
    @include size(tablet-up){
      aspect-ratio: 1.85;
      margin-bottom: 0;
    }
    &.big-img{
      position: relative;
      aspect-ratio: 1.85;
      height: auto;
      @include size(tablet-up){
        aspect-ratio: 1.25;
      }
    }
    img{
      max-width: 100%;
      height: 100%;
      object-fit: fill;
      width: 100%;
    }
    .black-overlay{
      @include position(absolute, 0, 0, 100%, 100%);
      @include flexM(flex, center, center);
      background-color: rgba(0, 0, 0, 0.6);
      a{
        @include font($font-size-20, bold, $white-clr);
        cursor: pointer;
        @include size(tablet-up){
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
.price-widget{
  background-color: #1e1e1e;
  border-radius: $input-radius;
  padding: 20px;
  width: 250px;
  position: absolute;
  bottom: 29px;
  left: 62px;
  @include size(tablet-up){
    display: flex;
    align-items: center;
    position: relative;
    left: auto;
    bottom: auto;
    width: 100%;
  }
  .title{
    h5{
      @include font($font-size-14, normal, $white-clr);
      opacity: 0.8;
      margin-bottom: 8px;
    }
    @include size(tablet-up){
      display: flex;
      width: max-content;
      margin-right: 30px;
    }
  }
  .des{
    display: flex;
    flex-direction: column;
    .price{
      @include font($font-size-20, $bold-font-8, $white-clr);
      margin-bottom: 3px;
      small{
        font-size: $font-size-15;
      }
      &.sm-price{
        @include font($font-size-h3, $bold-font-8, $white-clr);
        small{
          font-size: $font-size-14;
        }
      }
    }
    @include size(tablet-up){
      width: 60%;
    }
  }
}
.prop-detail-sec{
  .location{
    padding: 30px 0 6px 0;
    @include font($font-size-14, normal, $primary-txt-clr);
    @include flexM(flex, center, flex-start);
    em{
      width: 25px;
      height: 25px;
      background: #f4f4f4;
      @include flexM(inline-flex, center, center);
      border-radius: $input-radius;
      margin-right: 10px;
    }
  }
}
.prop-detail{
  .separator{
    border-bottom: 1px solid #d8d8d8;
  }
  // Heading style
  .heading{
    margin-top: 14px;
    h1{
      @include font($font-size-24, $heading-font, $primary-txt-clr);
      line-height: 1.25;
      margin-bottom: 3px;
      @include size(tablet-up){
        font-size: $font-size-20;
      }
    }
    p{
      @include font($font-size-13, normal, $primary-txt-clr);
      line-height: 1.85;
      word-break: break-word;
    }
    .like-icon{
      width: 40px;
      height: 40px;
      background: #ededed;
      @include flexM(inline-flex, center, center);
      border-radius: $input-radius;
      cursor: pointer;
    }
  }
  // Apartment Detail style
  .apartment-detail{
    padding: 23px 0 27px 0;
    @include size(tablet-up){
      padding: 23px 0 11px 0;
    }
    .col{
      max-width: 20%;
      @media (max-width: 1400px){
        max-width: 25%;
      }
      @media (max-width: 1200px){
        max-width: 30%;
      }
      @include size(tablet-up){
        max-width: 50%;
        flex: auto;
      }
    }
    .item{
      display: flex;
      @include size(tablet-up){
        margin-bottom: 20px;
      }
      img{
        margin-right: 9px;
      }
      p{
        @include font($font-size-18, normal, $primary-txt-clr);
        @include size(tablet-up){
          font-size: $font-size-16;
        }
      }
    }
  }
  // Description style
  .description{
    padding: 25px 0 50px 0;
    @include size(tablet-up){
      padding: 30px 0;
    }
    h4{
      @include font($font-size-16, normal, $gray-color-53);
      margin-bottom: 13px;
    }
    p{
      @include font($font-size-15, normal, $primary-txt-clr);
      line-height: 2.13;
      word-break: break-word;
    }
  }
  // Allow for style
  .allow-for{
    padding: 40px 0;
    @include size(tablet-up){
      padding: 20px 0 0;
    }
    .item{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      @include size(tablet-up){
        margin-bottom: 20px;
      }
      .icon{
        display: flex;
        width: 50px;
        margin-right: 15px;
        @include size(tablet-up){
          width: 30px;
        }
        img{
          @include size(tablet-up){
            max-width: 30px;
            height: auto;
          }
        }
      }
      .text{
        display: flex;
        width: calc(100% - 65px);
        flex-flow: column;
        span{
          @include font($font-size-16, normal, $gray-color-53);
          line-height: 1.5;
          @include size(tablet-up){
            font-size: $font-size-14;
          }
        }
        p{
          @include font($font-size-16, normal, $primary-txt-clr);
          line-height: 1.88;
          text-transform: capitalize;
          @include size(tablet-up){
            font-size: $font-size-14;
          }
        }
      }
      &.mr-b-55{
        margin-bottom: 55px;
        @include size(tablet-up){
          margin-bottom: 20px;
        }
      }
    }
  }
  // Property prices style
  .prop-price{
    padding: 40px 0;
    @include size(tablet-up){
      padding: 20px 0;
    }
    h3{
      @include font($font-size-20, normal, $primary-txt-clr);
      margin-bottom: 30px;
      @include size(tablet-up){
        margin-bottom: 20px;
      }
    }
    .item{
      span{
        @include font($font-size-16, normal, $gray-color-53);
        line-height: 1.5;
        @include size(tablet-up){
          font-size: $font-size-14;
        }
      }
      p{
        @include font($font-size-16, normal, $primary-txt-clr);
        line-height: 1.88;
        @include size(tablet-up){
          font-size: $font-size-14;
        }
      }
      &.mr-b-24{
        margin-bottom: 24px;
        @include size(tablet-up){
          margin-bottom: 20px;
        }
      }
    }
  }
  // Property Facilities style
  .facilities{
    padding: 40px 0;
    @include size(tablet-up){
      padding: 20px 0 0;
    }
    h3{
      @include font($font-size-20, normal, $primary-txt-clr);
      margin-bottom: 30px;
      @include size(tablet-up){
        margin-bottom: 20px;
        font-size: $font-size-18;
      }
    }
    .item{
      display: flex;
      @include size(tablet-up){
        margin-bottom: 27px;
      }
      p{
        @include font($font-size-15, normal, $primary-txt-clr);
        img{
          margin-right: 15px;
          @include size(tablet-up){
            max-width: 16px;
            height: auto;
            margin-right: 5px;
          }
        }
        @include size(tablet-up){
          font-size: $font-size-13;
        }
      }
      &.mr-b-48{
        margin-bottom: 48px;
        @include size(tablet-up){
          margin-bottom: 27px;
        }
      }
    }
  }
  .property-facilities{
    .table-item{
      span{
        @include font($font-size-16, normal, $gray-color-53);
        line-height: 1.5;
      }
      p{
        @include font($font-size-16, normal, $primary-txt-clr);
        line-height: 1.5;
      }
    }
  }
  // Rating review style
  .rating-review{
    padding: 40px 0;
    @include size(tablet-up){
      padding: 20px 0 40px;
    }
    h3{
      @include font($font-size-20, normal, $primary-txt-clr);
      margin-bottom: 35px;
      @include size(tablet-up){
        margin-bottom: 20px;
      }
    }
    &.fac-tab {
      padding-bottom: 20px;
    }
    // Rating style
    .rating{
      margin-bottom: 20px;
      .item{
        padding: 15px 20px;
        border-radius: $input-radius;
        display: flex;
        position: relative;
        margin-bottom: 10px;
        min-height: 60px;
        .img{
          margin-right: 10px;
        }
        .txt{
          p{
            @include font($font-size-13, $lt-font, $white-clr);
            opacity: 0.8;
          }
          h4{
            @include font($font-size-15, $semi-font, $white-clr);
          }
        }
        .rate{
          position: absolute;
          right: 20px;
          display: flex;
          h3{
            @include font($font-size-22, $e-bold-font, $white-clr);
          }
          i{
            cursor: pointer;
            max-height: 22px;
            img{
              filter: brightness(11);
              margin-top: 2px;
            }
            .tool-tip{
              display: none;
              position: absolute;
              width: max-content;
              top: -47px;
              right: -19px;
              font-style: normal;
              background: #333;
              color: #ffffff;
              padding: 9px 18px;
              font-size: 12px;
              border-radius: 18px;
              max-height: 100%;
            }
            &:hover{
              .tool-tip{
                display: block;
              }
            }
          }
        }
        &.light-pink{
          @include gradient(to right, $gradient-color);
        }
        &.green{
          @include gradient(to left, $succ-gradient-color);
        }
        &.gray{
          background: $gray-color;
        }
      }
      &.view-section {
        margin-bottom: 0px;
      }
    }
    // Review style
    .review{
      .item{
        box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
        background-color: $white-clr;
        margin-bottom: 15px;
        border-radius: $input-radius;
        padding: 20px;
        span{
          @include font($font-size-13, normal, $gray-color);
          @include flexM(flex, center, flex-end)
        }
        p{
          @include font($font-size-15, normal, $gray-color);
          line-height: 1.87;
          margin-top: 20px;
          word-break: break-word;
        }
      }
    }
  }
}
.book-now-form{
  border-radius: 4px;
  box-shadow: 2px 5px 20px 0 rgba(33, 35, 38, 0.05);
  background-color: $white-clr;
  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
  @include size(tablet){
    margin-bottom: 50px;
  }
  @include size(tablet-up){
    display: none;
    background: $white-clr;
    @include position(fixed, 0, 0, 100%, 100%);
    z-index: 999;
    overflow: auto;
  }
  &.active{
    @include size(tablet-up){
      display: flex;
      flex-flow: column;
    }
  }
  .separator{
    border-bottom: 1px solid #d8d8d8;
  }
  // main form style
  .main-from{
    padding: 30px;
    @include size(tablet-up){
      padding: 30px 15px 20px;
    }
    .heading{
      margin-bottom: 30px;
      .price{
        @include font($font-size-19, $bold-font-8, $primary-txt-clr);
        margin-bottom: 3px;
        display: flex;
        line-height: 1.58;
        small{
          @include font($font-size-19, normal, $primary-txt-clr);
          line-height: 1.58;
          font-style: italic;
          margin-left: 4px;
        }
        &.sm-price{
          @include font($font-size-15, normal, $primary-txt-clr);
          small{
            font-size: $font-size-15;
          }
        }
      }
      .available{
        justify-content: flex-end;
        display: flex;
        @include font($font-size-15, normal, $primary-light-clr);
        line-height: 2.14;
        text-transform: $capital;
      }
    }
    .gray-block{
      background-color: $light-gray;
      padding: 13px 20px;
      border-radius: $input-radius;
      margin-bottom: 20px;
      min-height: 66px;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      .text{
        @include flexM(flex, flex-start, center);
        height: 100%;
        flex-flow: column;
        h4{
          @include font($font-size-13, normal, $primary-txt-clr);
        }
        h3{
          @include font($font-size-15, $med-font, $primary-txt-clr);
          margin-bottom: 5px;
        }
        a{
          @include font($font-size-12, normal, $primary-light-clr);
          text-decoration: underline;
          margin-top: 5px;
        }
      }
      .spinner{
        background-color: $white-clr;
        padding: 13px 7px;
        display: inline-flex;
      }
      .amount{
        @include font($font-size-14, normal, $primary-txt-clr);
        @include flexM(flex, center, flex-end);
        height: 100%;
      }
      .flex-end{
        @include flexM(flex, center, flex-end);
      }
    }
    .react-datepicker-wrapper{
      width: 100%;
      input{
        &::placeholder{
          color: #7d7d7d;
          font-weight: 500;
        }
      }
    }
  }
  // Apply coupon style
  .apply-coupon{
    padding: 20px 30px;
    @include size(tablet-up){
      padding: 20px 15px;
    }
    h6{
      @include font($font-size-12, normal, $black-color);
      margin-bottom: 20px;
    }
    .button{
      margin-top: 7px;
      z-index: 0;
    }
    strong{
      @include font($font-size-12, $heading-font, $gray-color);
      line-height: 1.42;
      margin-bottom: 7px;
      display: flex;
    }
    p{
      @include font($font-size-12, normal, $gray-color);
      line-height: 1.58;
      margin-bottom: 12px;
    }
    a{
      @include font($font-size-12, normal, $primary-light-clr);
      text-decoration: underline;
      margin-top: 6px;
    }
  }
  // Price breakdown style
  .price-breakdown{
    padding: 30px;
    @include size(tablet-up){
      padding: 30px 15px;
    }
    h3{
      @include font($font-size-15, $heading-font, $black-color);
      margin-bottom: 28px;
      text-decoration: underline;
    }
    .item{
      p{
        padding: 10px 9px;
        @include font($font-size-13, normal, $black-color);
      }
      &.total{
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        p{
          @include font($font-size-15, $med-font, $black-color);
          padding: 15px 9px;
        }
      }
    }
    .btn-wrp{
      margin: 30px 0 6px;
    }
  }
  .sms-action-button{
    width: 100%;
    padding: 30px 30px 0;
    @include size(tablet-up){
      padding: 20px 15px;
    }
    .status-btn{
      width: 100%;
      padding: 17px;
      font-size: 12px;
      @include size(tablet-up){
        padding: 16px;
        font-size: 11px;
      }
    }
  }
}
.price-breakdown-mob{
  padding: 17px 20px;
  flex-flow: column;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: $white-clr;
  h3{
    @include font($font-size-15, $heading-font, $black-color);
    margin-bottom: 28px;
    text-decoration: underline;
  }
}
.pop-heading{
  padding: 40px 20px;
  flex-wrap: wrap;
  h3{
    @include font($font-size-20, $heading-font, $primary-txt-clr);
    width: calc(100% - 25px);
  }
  .close{
    @include flexM(flex, center, flex-end);
    width: 25px;
  }
}
.booking-pg-btn {
  padding: 0 30px 30px;
  button:disabled{
    margin-top: 30px;
  }
}
.form-container{
  .listing-details{
    .row{
      @include size(phone){
        --bs-gutter-x: 0.625rem;
      }
    }
    .thumb-item {
      aspect-ratio: 1.9;
      height: auto;
      @include size(phone){
        aspect-ratio: 1.85;
      }
      &.mr-b-20{
        @include size(phone){
          margin-bottom: 0.7rem;
        }
      }
      &.big-img{
        aspect-ratio: 1.85;
        height: auto;
        @include size(tablet-up){
          aspect-ratio: 1.22;
        }
      }
      img{
        max-height: inherit;
        max-width: 100%;
        height: 100%;
        object-fit: fill;
        width: 100%;
      }
    }
  }
}
.col-reverse-web{
  .btn-primary{
    order: 2;
  }
}
.checkin-checkout-detail{
  .user-detail{
    margin-bottom: 50px;
    .check-in-block{
      background-color: #ffffff;
      padding: 0 0 0 10px;
      width: 100%;
    }
    .row{
      width: 100%;
      > div{
        padding: 0;
      }
      &.radio-option{
        width: 100%;
        margin-top: 20px;
        .check-list{
          background-color: #f8f8f8;
          border-radius: 0.625rem;
          padding: 22px 25px;
          margin: 10px 0;
        }
        .col-md-6{
          width: calc(50% - 10px);
          &:nth-child(odd){
            margin-right: 10px;
          }
          &:nth-child(even){
            margin-left: 10px;
          }
        }
      }
    }
    .table-body{
      width: 100%;
      padding: 0;
      .form-floating{
        .form-control{
          background: $light-gray;
          min-height: 150px;
        }
        &.min-height-inherit{
          .form-control{
            min-height: inherit;
          }
        }
      }
    }
  }
}
.booking-details-button{
  position: relative;
  @include size(tablet-up){
    flex-flow: row wrap;
  }
  a{
    @include size(tablet-up){
      width: 100%;
    }
    .button{
      @include size(tablet-up){
        margin-left: 0;
      }
    }
  }
  .button{
    font-size: 12px;
    padding: 0.755rem 1rem;
    @include size(tablet-up){
      width: 47% !important;
      font-size: 12px;
      margin-top: 15px;
    }
    @include size(phone){
      width: 100% !important;
      margin-left: 0;
    }
    &.light-purpple-bg{
      @include size(tablet-up){
        margin-left: 0;
      }
      @include size(phone){
        margin-top: 0;
      }
    }
  }
  .show-top-in-mob{
    @include size(tablet-up){
      position: absolute !important;
      top: -64px;
    }
  }
}
.booking-review{
  width: 100%;
  h2{
    @include font($font-size-18, 800, #212326);
  }
  .review{
    width: 100%;
    .table-body{
      padding: 20px 0;
      margin: 0;
    }
    .rating-stars{
      img{
        width: 25px;
        height: 25px;
      }
    }
    .reviwe-title{
      h5{
        @include font($font-size-14, 600, #212326);
        margin: 25px 0 20px;
        word-break: break-word;
      }
    }
    .review-des{
      p{
        font-size: $font-size-14;
        line-height: 22px;
        word-break: break-word;
      }
    }
    .align-right{
      @include size(tablet-up){
        justify-content: flex-start;
        margin-top: 10px;
      }
    }
  }
}