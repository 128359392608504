.bank-accont-modal{
  .modal-dialog{
    max-width: 580px;
  }
}
.modal-dialog{
  min-height: 100%;
  margin: auto;
  @include flexM(flex, center, center);
  margin: 40px auto;
  @media (max-width: 767px) {
    width: calc(100% - 20px);
  }
}
.modal-content{
  border: none;
  border-radius: $input-radius;
  .modal-box{
    padding: 30px;
    @include size(tablet-up){
      padding: 30px 20px;
    }
    @media (max-width: 767px) {
      max-height: 650px;
      overflow: scroll;
    }
    .header{
      margin-bottom: 30px;
      h2{
        position: relative;
        .cross-btn{
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }
      h3{
        @include font($font-size-h3, $bold-font, $primary-txt-clr);
        margin-bottom: 5px;
        position: relative;
        .cross-btn{
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }
      p{
        @include font($font-size-md, normal, $gray-color);
        line-height: 24px;
      }
    }
    .button-wrp{
      .btn{
        width: 50%;
        font-size: $font-size-12;
        padding: 11px;
        @media (max-width: 767px){
          width: 100%;
        }
      }
      &.flex-end{
        justify-content: flex-end;
        margin-top: 30px;
        .btn{
          max-width: 180px;
        }
      }
    }
  }
  .lease-agreement{
    padding: 0;
    .text{
      max-height: 500px;
    }
  }
}
.rating-pop{
  .modal-dialog{
    max-width: 400px;
  }
  .modal-box{
    padding: 50px;
    text-align: center;
    @media (max-width: 420px){
      padding: 40px 25px;
    }
    .button-wrp{
      .btn{
        width: 100%;
      }
    }
  }
  .rating-stars{
    @include flexM(flex, center, center);
  }
  .rating-txt {
    @include font($font-size-md, normal, $primary-light-clr);
  }
  .button-2-rating{
    display: flex;
    margin-top: 30px;
  }
}
.pre-history-modal{
  .modal-dialog{
    max-width: 690px;
    .modal-content{
      background-color: $light-gray;
    }
  }
}
.reject--list-pop{
  &.w-650{
    .modal-dialog{
      max-width: 650px;
    }
  }
  .modal-dialog{
    max-width: 400px;
    .check-list{
      margin-bottom: 15px;
      display: flex;
      label{
        line-height: 1.57;
        display: flex;
        align-items: flex-start;
        &::after, &::before{
          margin: 3px 15px 0 0;
        }
      }
      input{
        margin-top: 3px;
        &:after{
          top: 5px;
        }
      }
      &.mr-b-25{
        margin-bottom: 22px;
      }
      &.mr-b-40{
        margin-bottom: 40px;
      }
    }
    .button-wrp{
      display: flex;
      position: relative;
      z-index: 0;
      @include size(phone){
        flex-flow: row wrap;
      }
      a{
        @include size(phone){
          width: 100%;
        }
      }
      .btn{
        border-radius: $input-radius;
        text-transform: $capital;
        &.btn-danger{
          margin-left: 15px;
          font-weight: 500;
          @include size(phone){
            order: 1;
            margin: 0 0 15px;
          }
        }
        &.btn-secondary{
          @include size(phone){
            order: 2;
          }
        }
        &.btn-primary{
          @include size(phone){
            order: 1;
            margin: 0 0 15px;
          }
        }
      }
    }
  }
  &.gray-bg{
    .modal-content{
      background: #f8f8f8;
    }
  }
}

.check-In-pop{
  .modal-dialog{
    max-width: 400px;
  }
  .thumb-img{
    img{
      max-width: 100%;
    }
  }
  .text{
    h5{
      @include font($font-size-12, normal, $gray-color);
      margin-bottom: 5px;
    }
    p{
      @include font($font-size-p, normal, $primary-txt-clr);
      line-height: 28px;
      word-break: break-word;
    }
  }
}
.w-720{
  .modal-dialog{
    max-width: 750px;
  }
}
.w-450{
  .modal-dialog{
    max-width: 450px;
  }
}
.w-500{
  .modal-dialog{
    max-width: 500px;
  }
}
.w-400{
  .modal-dialog{
    max-width: 400px;
  }
}
.w-480{
  .modal-dialog{
    max-width: 480px;
  }
}
.w-350{
  .modal-dialog{
    max-width: 350px;
  }
}
.feature-prop{
  .modal-dialog{
    max-width: 650px;
  }
  .modal-box{
    padding-bottom: 0;
    h3{
      max-width: 350px;
      margin: 0 auto;
      text-align: center;
      line-height: 30px;
    }
    p{
      max-width: 500px;
      margin: 0 auto;
      text-align: center;
      line-height: 30px;
      margin-top: 20px;
    }
    img{
      max-width: 100%;
    }
  }
}
.cancel-pop{
  .button-wrp{
    @media (max-width: 350px) {
      flex-flow: column;
    }
    .button{
      @media (max-width: 350px) {
        width: 100% !important;
        margin-left: 0 !important;
      }
      &.w-205{
        @media (max-width: 350px) {
          margin-top: 15px;
        }
      }
    }
  }
}
.lg-heading{
  @include font($font-size-lg, $heading-font, $primary-txt-clr);
  margin-bottom: 13px;
  @include size(tablet-up){
    font-size: $font-size-20;
  }
}
.check-icon-circle{
  width: 100px;
  height: 100px;
  box-shadow: 2px 5px 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-color: $white-clr;
  @include flexM(flex, center, center);
  margin: auto auto 30px;
}
.success-dec{
  max-width: 340px;
  margin: auto auto 30px;
}
.pricing{
  .item{
    padding: 15px 0;
    border-bottom: 1px solid #f5f5f5;
    &:last-of-type{
      border-bottom: none;
      padding-bottom: 0;
    }
    &:first-of-type{
      padding-top: 0;
    }
    p{
      @include font($font-size-14, normal, $gray-color);
      line-height: 24px;
      &.black-clr{
        color: $primary-txt-clr;
        @include flexM(flex, center, flex-end);
      }
      &.bolder{
        color: $primary-light-clr;
        @include flexM(flex, center, flex-end);
        font-weight: $heading-font;
      }
    }
  }
}
.review-popup{
  .modal-dialog{
    max-width: 500px;
    .modal-box{
      background-color: $light-gray;
      border-radius: $input-radius;
      .header{
        h3{
          font-weight: $bold-font-8;
        }
      }
    }
  }
  .review{
    max-height: 500px;
    overflow-y: auto;
    .item{
      margin-bottom: 20px;
      &:last-of-type{
        margin-bottom: 0;
      }
      .prop-img{
        border-radius: 50%;
      }
      .review-des{
        p{
          margin-top: 15px;
          line-height: 1.86;
          word-break: break-word;
        }
      }
      .table-body{
        margin-bottom: 0;
      }
    }
  }
}
.booking-pop{
  .modal-dialog{
    max-width: 600px;
    .light-pink{
      background-color: #fbf4fe;
      margin-bottom: 0;
    }
    .booking-info-table{
      background-color: $light-gray;
      border-radius: $input-radius;
      margin-bottom: 25px;
      .item{
        padding: 11px 24px;
        p{
          @include font($font-size-13, normal, $primary-txt-clr);
          &.align-right{
            @include flexM(flex, center, flex-end);
            font-size: $font-size-15;
          }
        }
        &.total{
          border-top: 1px solid #e4e4e4;
          p{
            font-weight: $semi-font;
          }
        }
      }
    }
    .file-upload{
      background-color: $light-gray;
      border-radius: $input-radius;
      padding: 12px 18px;
      p{
        @include font($font-size-15, normal, $primary-txt-clr);
        @include flexM(flex, center, flex-start);
        height: 100%
      }
      .flex-end{
        @include flexM(flex, center, flex-end);
      }
    }
    .go-to{
      a{
        @include font($font-size-14, normal, $primary-light-clr);
        text-decoration: underline;
      }
    }
    .button-wrp{
      @include flexM(flex, center, center);
      .button{
        width: 150px;
        min-height: 50px;
        @include size(tablet-up){
          width: 100% !important;
        }
      }
    }
    .modal-box{
      padding: 50px;
      @include size(phone){
        padding: 20px;
      }
    }
  }
}

.feature-help {
  h3{
    position: inherit !important;
    .cross-btn {
      padding: 20px !important;
    }
  }
}
.payment-form{
  .modal-box{
   @media (max-width: 420px) {
      padding: 20px 15px;
    }
  }
}
.mx-h-450{
  max-height: 450px;
  overflow-y: auto;
  p{
    line-height: 24px;
  }
}
.modal-backdrop{
  &.show{
    opacity: 0.9;
  }
}
.rating-pop.mr-0{
  .modal-dialog{
   margin: 0 auto;
  }
}
.image-modal{
  .img-view-container {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .close-modal{
    position: absolute;
    right: 25px;
    top: 25px;
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
  }
}